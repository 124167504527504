<template>
  <div class="moreTutor">
    <div class="main">
      <h4>导师专家推荐</h4>
      <h5>
        为会员及企业提供全行业的投融资、战略规划、品牌营销、企业管理等咨询交流与服务
      </h5>
      <div class="contain">
        <div
          class="item"
          v-for="item in tutorLists"
          :key="item.userId"
          @click="detail(item.userId)"
        >
          <div class="img_cup">
            <img :src="item.avatar" alt="" />
          </div>
          <div class="txt">
            <h3>{{ item.nickname }}</h3>
            <h6>{{ item.post | postFilter }}</h6>
          </div>
          <span>去咨询</span>
        </div>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="screenData.pageTo"
        :page-size="screenData.pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "More-tutor",
  data() {
    return {
      total: 0,
      tutorLists: [],
      screenData: {
        pageTo: 1,
        pageSize: 16,
      },
    };
  },
  filters: {
    postFilter(val) {
      let post = "";
      if (val.length > 50) {
        post = val.slice(0, 50) + "...";
      } else {
        post = val;
      }
      return post;
    },
  },
  methods: {
    detail(userId) {
      this.$router.push("/tutor-detail?userId=" + userId);
    },
    handleSizeChange(pageSize) {
      this.screenData.pageSize = pageSize;
      this.listShow(this.screenData);
    },
    handleCurrentChange(currentPage) {
      this.screenData.pageTo = currentPage;
      this.listShow(this.screenData);
    },
    listShow(data) {
      console.log(data);
      request.post("/in/website/mentorList", data).then((res) => {
        console.log(res);
        this.tutorLists = res.data.items;
        this.total = res.data.totalItemCount;
      });
    },
  },
  created() {
    this.listShow(this.screenData);
  },
};
</script>
<style lang="less">
.moreTutor {
  .el-icon-arrow-left:before {
    content: "上一页";
    color: #666666;
    font-size: 16px;
    font-weight: 500;
  }
  .el-icon-arrow-right:before {
    content: "下一页";
    color: #666666;
    font-size: 16px;
    font-weight: 500;
  }
  .el-pagination__jump {
    font-size: 16px;
    color: #333333;
  }
  .el-pagination {
    text-align: center;
    margin-top: 70px;
    font-weight: 500;
    .btn-prev,
    .btn-next {
      height: 38px;
      line-height: 38px;
      width: 76px;
      border-radius: 10px;
      margin-right: 8px;
    }
  }
  .el-pagination__editor.el-input .el-input__inner {
    height: 38px;
    line-height: 38px;
  }
  .el-pagination__editor.el-input {
    width: 76px;
  }
  .el-pager li.active {
    background: #ff7b00;
    color: #fff;
  }
  .el-pager li {
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    font-size: 16px;
    color: #333333;
    border-radius: 10px;
    margin-right: 10px;
  }
  .el-pager li:hover {
    background: #ff7b00;
    color: #fff;
  }
}
</style>
<style lang="less" scoped>
.moreTutor {
  background: #f7f7f7;

  .main {
    width: 1200px;
    margin: 0 auto;
    padding: 65px 0 89px;
    box-sizing: border-box;
    .contain {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .item:hover {
        .img_cup {
          position: absolute;
          left: 0;
          top: 0;
          width: 285px;
          height: 360px;
          border-radius: 0px;
          img {
            width: 285px;
            height: 294px;
          }
        }
        .txt {
          display: none;
        }
        span {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 66px;
          line-height: 66px;
          display: block;
          background: #ff7b00;
          font-size: 20px;
          color: #fff;
          text-align: center;
        }
      }
      .item {
        width: 285px;
        height: 360px;
        padding: 20px 30px 10px;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        background: #fff;
        margin: 0px 20px 20px 0;
        position: relative;
        cursor: pointer;
        .img_cup {
          width: 186px;
          height: 186px;
          margin: 0 auto;
          border-radius: 186px;
          overflow: hidden;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
        .txt {
          text-align: center;
          h3 {
            font-size: 20px;
            color: #333333;
            font-weight: 600;
            margin: 10px auto 7px;
            width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          h6 {
            font-weight: normal;
            font-size: 14px;
            color: #333333;
            margin: 0;
            line-height: 22px;
            height: 80px;
            text-overflow: ellipsis;
            white-space: wrap;
            overflow: hidden;
          }
        }
        span {
          display: none;
        }
      }
      .item:nth-child(4n) {
        margin: 0px 0px 20px 0;
      }
    }
    h4 {
      //   margin-bottom: 10px;
      width: 100%;
      font-weight: normal;
      font-size: 36px;
      line-height: 50px;
      color: #333333;
      text-align: center;
      margin-bottom: 10px;
    }
    h5 {
      width: 100%;
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
      color: #333333;
      text-align: center;
      margin-bottom: 38px;
    }
  }
}
</style>