<template>
  <div class="join">
    <div class="banner">
      <img src="https://cdn.sdh365.com/ow/joinusbg.png" alt="" />
    </div>
    <h4>职位<span>信息</span></h4>
    <div class="contain">
      <div class="list">
        <div class="flex">
          <div class="title" v-for="(post, index) in posts" :key="index">
            {{ post.post }}
          </div>
        </div>
        <div class="collapse">
          <!-- <el-collapse accordion> -->
          <el-collapse v-model="activeNames" @change="collapseChange">
            <el-collapse-item v-for="(item, index) in postLists" :key="index">
              <template slot="title">
                <div class="flex_content">
                  <span>{{ item.name }}</span>
                  <span>{{ item.classify }}</span>
                  <span>{{ item.site }}</span>
                  <span>{{ item.num }}</span>
                  <span>{{ item.insertTime | formatDateNoTime }}</span>
                </div>
              </template>
              <div class="padding">
                <p v-html="item.content" class="text"></p>
                <span>如感兴趣请投简历至邮箱：hyxny12345@163.com</span>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="screenData.pageTo"
        :page-size="screenData.pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "Join-us",
  data() {
    return {
      total: 0,
      screenData: {
        pageTo: 1,
        pageSize: 10,
        order: 1,
      },
      activeNames:[],
      postLists: [],
      posts: [
        { post: "职位名称" },
        { post: "职位类别" },
        { post: "工作地点" },
        { post: "招聘人数" },
        { post: "更新时间" },
      ],
    };
  },
  filters: {
    formatDateNoTime(a) {
      var now = new Date(parseInt(a));
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      if (month.toString().length == 1) {
        month = "0" + month.toString();
      }
      var date = now.getDate();
      if (date.toString().length == 1) {
        date = "0" + date.toString();
      }
      var hour = now.getHours();
      if (hour.toString().length == 1) {
        hour = "0" + hour.toString();
      }
      var minute = now.getMinutes();
      if (minute.toString().length == 1) {
        minute = "0" + minute.toString();
      }
      var second = now.getSeconds();
      if (second.toString().length == 1) {
        second = "0" + second.toString();
      }
      return year + "-" + month + "-" + date;
    },
  },
  methods: {
    collapseChange(val){
console.log(val)
this.activeNames=val;
    },
    postList(data) {
      request.post("/in/website/postList", data).then((res) => {
        console.log(res);
        this.postLists = res.data.items;
        // this.postLists.forEach((item)=>{
        // let strIndex=item.content.indexOf('岗位职责')
        // let strIndex=item.content.indexOf('岗位职责')
        // let newcontent1=item.content.substring(5)
        // item.content=newcontent1;
        // console.log('666'+strIndex)
        // console.log('666'+newcontent1)
        // })

        this.total = res.data.totalItemCount;
      });
    },
    handleSizeChange(pageSize) {
      this.screenData.pageSize = pageSize;
      this.postList(this.screenData);
      this.activeNames=[];
    },
    handleCurrentChange(currentPage) {
      this.screenData.pageTo = currentPage;
      this.postList(this.screenData);
      this.activeNames=[];
    },
  },
  created() {
    this.postList(this.screenData);
  },
};
</script>
<style lang="less">
.join {
  strong {
    font-size: 18px;
    font-weight: 700;
    color: #333333;
    padding: 0;
    margin: 0;
    margin-bottom: 3px;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
  }
  .el-collapse-item__arrow {
    margin: 2px 48px 0 auto;
    font-weight: 800;
    font-size: 20px;
  }
  .el-collapse {
    border-top: 1px solid rgba(0, 0, 0, 0.03);
    border-bottom: none;
  }
  .el-collapse-item__header {
    height: 58px;
    line-height: 58px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  }
  .el-collapse-item__wrap {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  }
  //   .el-collapse-item__header.is-active{
  //         border-bottom-color: transparent;
  //     }
  .pagination {
    .el-icon-arrow-left:before {
      content: "上一页";
      color: #666666;
      font-size: 16px;
      font-weight: 500;
    }
    .el-icon-arrow-right:before {
      content: "下一页";
      color: #666666;
      font-size: 16px;
      font-weight: 500;
    }
    .el-pagination__jump {
      font-size: 16px;
      color: #333333;
    }
    .el-pagination {
      text-align: center;
      margin: 80px auto 0;
      font-weight: 500;
      .btn-prev,
      .btn-next {
        height: 38px;
        line-height: 38px;
        width: 76px;
        border-radius: 10px;
        margin-right: 8px;
      }
    }
    .el-pagination__editor.el-input .el-input__inner {
      height: 38px;
      line-height: 38px;
    }
    .el-pagination__editor.el-input {
      width: 76px;
    }
    .el-pager li.active {
      background: #ff7b00;
      color: #fff;
    }
    .el-pager li {
      width: 38px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      font-size: 16px;
      color: #333333;
      border-radius: 10px;
      margin-right: 10px;
    }
    .el-pager li:hover {
      background: #ff7b00;
      color: #fff;
    }
  }
}
</style>
<style lang="less" scoped>
.join {
  background: #f7f7f7;
  padding-bottom: 117px;
  box-sizing: border-box;
  width: 100%;
  .banner {
    width: 100%;
    margin-bottom: 68px;
    img {
      width: 100%;
    }
  }
  h4 {
    font-size: 48px;
    font-weight: 800;
    text-align: center;
    color: #333333;
    span {
      display: inline-block;
      color: #ff7b00;
      margin-bottom: 20px;
    }
  }
  .contain {
    width: 1200px;
    // height: 648px;
    margin: 0 auto;
    background: #fff;
    .padding {
      padding: 36px 20px 36px 0;
      box-sizing: border-box;
      color: #666;
      font-size: 16px;
      span {
        width: 360px;
        height: 40px;
        line-height: 40px;
        background: #ff7b00;
        border-radius: 4px;
        display: block;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        margin-top: 36px;
      }
      // h6 {
      //   font-size: 18px;
      //   font-weight: 700;
      //   color: #333333;
      //   padding: 0;
      //   margin: 0;
      //   margin-bottom: 3px;
      // }
    }
    .list {
      padding-left: 22px;
      box-sizing: border-box;
      .collapse {
        .flex_content {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          span {
            display: block;
            width: 100px;
            font-size: 16px;
            height: 58px;
            line-height: 58px;
            font-weight: 500;
            color: #333333;
            margin-right: 100px;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          span:first-child {
            width: 200px;
            margin-right: 80px;
          }
          span:last-child {
            margin-right: 0px;
            width: 120px;
          }
        }
      }
      .flex {
        display: flex;
        justify-content: flex-start;
      }
      .title {
        // width: 80px;
        height: 68px;
        line-height: 68px;
        font-size: 20px;
        font-weight: 700;
        text-align: LEFT;
        color: #333333;
        margin-right: 117px;
      }
      .title:first-child {
        margin-right: 203px;
      }
      .title:last-child {
        margin-right: 0px;
        margin-left: 4px;
      }
    }
  }
}
</style>