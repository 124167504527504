<template>
  <div class="main">
    <div class="banner">
      <section class="ivideo_banner act" style="max-height: none;overflow: hidden;">
        <video autoplay="" loop="" muted="" id="video" width="100%">
          <source src="https://cdn.sdh365.com/ow/shidonghui_publicity01.mp4" type="video/mp4;" />
        </video>
      </section>
    </div>
    <div class="banner2">
      <h2>打造千亿产业上市共同体</h2>
      <div class="banner2_box">
        <div class="left">
          <div>· 支持创新创业科技孵化</div>
          <p>“大力创业，科技创新”；师董会连续六年帮扶中小企业创新创业</p>
          <div>· 支持地方政府培育经济增长点</div>
          <p>签约落户淄博市、推进数字经济发展、与政府合作举办线下论坛活动</p>
          <div>· 支持中国企业走向全球</div>
          <p>建立科学的企业价值评价机制，提升中国企业竞争力，推进企业上市</p>
          <div>· 支持传统企业转型升级</div>
          <p>
            技术实现能力更是企业转型升级的载体和突破口，选择一家专业靠谱的服务公司，选师董会呀！
          </p>
          <el-button class="btn1" type="primary" @click="showPopMessage">获取专家小组服务方案</el-button>
          <el-button class="btn2" type="primary" @click="showChat">在线咨询</el-button>
        </div>
        <img src="../../assets/pic_home.png" class="right" />
      </div>
    </div>
    <div class="banner3">
      <h3>赴美上市一体化服务</h3>
      <h5>多层次、多通道、多方式，指导企业挂牌上市</h5>
      <div class="banner3_box">
        <div class="banner3_flex">
          <div class="box">
            <img src="../../assets/icon_home2.png" class="icon" />
            <p class="title">五大项目优势</p>
            <div class="list">
              <p>上市流程不走弯路</p>
              <p>手握大量上市资源</p>
              <p>上市难点全部扫清</p>
              <p>正规化的市值管理</p>
              <p>大量资本助力发行</p>
            </div>
          </div>
          <div class="box">
            <img src="../../assets/icon_home.png" class="icon" />
            <p class="title">六大团队优势</p>
            <div class="list">
              <p>经验丰富</p>
              <p>专业强化</p>
              <p>成功率高</p>
              <p>降低成本</p>
              <p>筹划税务</p>
              <p>资源协调</p>
            </div>
          </div>
          <div class="box">
            <img src="../../assets/icon_home3.png" class="icon" />
            <p class="title">三大方式途径</p>
            <div class="list">
              <p>做壳上市，首度公开发行</p>
              <p>另类上市，定向公开发行</p>
              <p>反向收购，借壳登市发行</p>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-box">
        <el-button class="btn1" type="primary" @click="showPopMessage">获取专家小组服务方案</el-button>
        <el-button class="btn2" type="primary" @click="showChat">在线咨询</el-button>
      </div>
    </div>
    <div class="banner4">
      <Message></Message>
    </div>
    <div class="banner5">
      <h3>导师专家推荐</h3>
      <h5>
        为会员及企业提供全行业的投融资、战略规划、品牌营销、企业管理等咨询交流与服务
      </h5>
      <div class="box clear-fix" v-if="picShowList.length > 0" @mouseover="clearInv" @mouseout="runInv">
        <div class="pre" @click="toogle(-1)"></div>
        <div v-for="c of 5" :key="c" class="photo-box">
          <div class="photo" :class="c == 3 ? 'photo-active' : ''" :style="{backgroundImage: 'url(' + picShowList[c - 1].avatar + ')'}" @click="jump(c)"></div>
          <div class="name" v-show="c == 3">
            {{ picShowList[c - 1].nickname }}
          </div>
          <div class="info" v-show="c == 3">
            {{ picShowList[c - 1].individualResume }}
          </div>
        </div>
        <div class="next" @click="toogle(1)"></div>
      </div>
      <div>
        <el-button class="btn2" type="primary" @click.native="moreTutor">查看更多</el-button>
      </div>
    </div>
    <div class="banner6">
      <h3>区域总部落户，助力地方政府打造新经济增长点</h3>
      <h5>
        专注于为地方政府提供招商引资、产业落地、成果转化等服务，助力当地企业创新转型升级和区域经济发展
      </h5>
      <div class="banner6_box">
        <img src="../../assets/pic_home2.png" class="left" />
        <div class="right">
          <div>· 多联动搭建数字产业链</div>
          <p>
            集数字生态产业园、数字峰会经济、线上资源共享平台、直播网红体于一体
          </p>
          <div>· 大流量品质高峰论坛</div>
          <p>超2亿次传播曝光、对接政商产学研高端资源、人脉、资源精准触达</p>
          <div>· 全方位资本孵化</div>
          <p>
            对接区域交易所、设立资本孵化营、共建产业基金、助力区域企业转型发展
          </p>
          <div>· 高效益企业生态链</div>
          <p>
            纳斯达克上市、科技创新、产业投资、中小企业入驻、多重效益推区域经济发展
          </p>
          <div>· 多渠道贡献企业税收</div>
          <p>
            招商引资、产业入驻、政策扶持、平台引进、多渠道稳定保证提升区域税收
          </p>
          <el-button class="btn1" type="primary" @click="showPopMessage">获取专家小组服务方案</el-button>
          <el-button class="btn2" type="primary" @click="showChat">在线咨询</el-button>
        </div>
      </div>
    </div>
    <div class="banner7">
      <h3>服务案例</h3>
      <h5>
        成功为会员提供包括扶持上市、投融资、企业管理、业务资源对接、教育医疗等多个方面的深度服务
      </h5>
      <div class="banner7_box">
        <div class="left">
          <div class="title">会员好评</div>
          <div class="box">
            <div class="text">
              我是会员李庆星，在师董会主席胡海平的帮助与投
              资中，我司发展迅猛。在2016年5月25日，我司决
              策者在“新三板”正式挂牌（证券代码：837621），
              同时决策者成为中国新三板首家挂牌上市的商务会 议公司。
            </div>
            <div class="name">李庆星</div>
            <div class="company">上海决策者经济顾问股份有限公司</div>
            <div class="photo liqingxing"></div>
          </div>
          <div class="box">
            <div class="text">
              我是会员朱芳义，一直为传贝智慧支付项目的资金而
              焦灼不已，于是我拜访了师董会主席胡海平。在胡主
              席的战略对接与引荐后，我成功获得500万融资，并
              得到与家电卖场合作的机会，更对接了省家电协会。
            </div>
            <div class="name">朱芳义</div>
            <div class="company">中国乌镇壹传科技公司</div>
            <div class="photo zhufangyi"></div>
          </div>
          <div class="box">
            <div class="text">
              我是钻石会员冯天仁，在一次线下游学活动中，我
              通过师董会客服介绍认识了白金会员岳文浩。经过
              几次沟通交流后达成合作，我们共同出资成立上海
              忆链物联网公司，并牵头完成LNG多式联运物流方案。
            </div>
            <div class="name">冯天仁</div>
            <div class="company">上海忆链物联网公司</div>
            <div class="photo fengtianren"></div>
          </div>
          <div class="box">
            <div class="text">
              我是徐宝华，师董会主席胡海平投资我公司的烟台惠
              通网络技术有限公司的无线充电项目。如今，该公司
              发展如火如荼，回报翻了10倍，已成为行业翘楚。
            </div>
            <div class="name">徐宝华</div>
            <div class="company">烟台惠通网络技术有限公司</div>
            <div class="photo xubaohua"></div>
          </div>
        </div>
        <div class="right">
          <div class="title">服务案例</div>
          <div class="more">
            <router-link to="/membership">查看更多></router-link>
          </div>
          <div class="border">
            <div class="type">扶持上市</div>
            <router-link class="text" :to="ipohelp1.articleId ? `/membership-detail?id=${ipohelp1.articleId}` : ''">· {{ ipohelp1.title }}</router-link>
            <router-link class="text" :to="ipohelp2.articleId ? `/membership-detail?id=${ipohelp2.articleId}` : ''">· {{ ipohelp2.title }}</router-link>
          </div>
          <div class="border">
            <div class="type">投融资</div>
            <router-link class="text" :to="financing1.articleId ? `/membership-detail?id=${financing1.articleId}` : ''">· {{ financing1.title }}</router-link>
            <router-link class="text" :to="financing2.articleId ? `/membership-detail?id=${financing2.articleId}` : ''">· {{ financing2.title }}</router-link>
          </div>
          <div class="border">
            <div class="type">业务对接</div>
            <router-link class="text" :to="business1.articleId ? `/membership-detail?id=${business1.articleId}` : ''">· {{ business1.title }}</router-link>
            <router-link class="text" :to="business2.articleId ? `/membership-detail?id=${business2.articleId}` : ''">· {{ business2.title }}</router-link>
          </div>
          <div>
            <div class="type">教育医疗</div>
            <router-link class="text" :to="education1.articleId ? `/membership-detail?id=${education1.articleId}` : ''">· {{ education1.title }}</router-link>
            <router-link class="text" :to="education2.articleId ? `/membership-detail?id=${education2.articleId}` : ''">· {{ education2.title }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="banner8">
      <h3>会员产品</h3>
      <h5>
        用线上线下相结合的方式，从培训交流、产品推广、品牌营销、路演融资、企业微咨询等方面为不同需求的会员全面赋能
      </h5>
      <Swiper @signUp="signUp" btntext="查看服务权益"></Swiper>
    </div>
    <div class="banner9">
      <h3>新闻动态</h3>
      <div class="banner9_box">
        <div class="left">
          <div class="title">最新动态</div>
          <div class="more">
            <router-link to="/news">查看更多></router-link>
          </div>
          <router-link class="l" v-if="recentlyList[0]" :to="`/news-detail?id=${recentlyList[0].articleId}`">
            <div class="picImg">
              <div class="pic" :style="{ backgroundImage: 'url(' + recentlyList[0].cover + ')' }"></div>
            </div>
            <div class="b">
              <h5>{{ recentlyList[0].title }}</h5>
              <span>{{ recentlyList[0].intro }}</span>
            </div>
          </router-link>
          <div class="r" v-if="recentlyList.length > 0">
            <router-link class="item clear-fix" v-for="c of 4" :key="c" :to="`/news-detail?id=${recentlyList[c].articleId}`">
              <div class="time">
                <span class="day">{{recentlyList[c].releaseTime.split("-")[2]}}</span>
                <span class="date">{{ recentlyList[c].releaseTime.split("-")[0] }}-{{recentlyList[c].releaseTime.split("-")[1]}}</span>
              </div>
              <div class="article">
                <h5>{{ recentlyList[c].title }}</h5>
                <span>{{ recentlyList[c].intro }}</span>
              </div>
            </router-link>
          </div>
        </div>
        <div class="right" v-if="activityList.length > 0">
          <div class="title">近期活动</div>
          <div class="more">
            <router-link to="/news">查看更多></router-link>
          </div>
          <router-link class="text" v-for="c in activityList.length" :key="c" :to="`/news-detail?id=${activityList[c - 1].articleId}`">· {{ activityList[c - 1].title }}</router-link>
        </div>
      </div>
      <div>
        <router-link to="/news" class="btn2">查看更多></router-link>
        <!-- <el-button type="primary" class="btn2">查看更多</el-button> -->
      </div>
    </div>
    <div class="banner10">
      <h3>深度合作伙伴</h3>
      <h5>多行业优秀的企业选择与师董会同行，携手共建智联网企业服务生态链</h5>
      <div class="tab" :class="banner10_tab == 'chanye' ? 'tab-active' : ''" @click="banner10_tab = 'chanye'">
        产业资源
      </div>
      <div class="tab" :class="banner10_tab == 'touhang' ? 'tab-active' : ''" @click="banner10_tab = 'touhang'">
        投行阵容
      </div>
      <div class="banner10_box">
        <div v-show="banner10_tab == 'chanye'" class="logo-box">
          <img v-for="index of 18" :key="index" :src="`https://cdn.sdh365.com/ow/chanye${index}.png`" class="logo" />
        </div>
        <div v-show="banner10_tab == 'touhang'" class="logo-box">
          <img v-for="index of 18" :key="index" :src="`https://cdn.sdh365.com/ow/touhang${index}.png`" class="logo" />
        </div>
      </div>
      <div>
        <el-button class="btn2" type="primary" @click="showChat">立即咨询</el-button>
      </div>
    </div>
    <div class="banner11">
      <Message btntext="提交留言"></Message>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
import Message from '../../components/message'
// import countTo from 'vue-count-to'
import Swiper from '../../components/swiper'
export default {
  name: 'Home',
  components: {
    Message,
    // countTo,
    Swiper,
  },
  data() {
    return {
      banner10_tab: 'chanye',
      picNum: 5,
      picList: [],
      picStart: 0,
      picEnd: 5,
      picShowList: [],
      ipohelp1: '',
      ipohelp2: '',
      financing1: '',
      financing2: '',
      business1: '',
      business2: '',
      education1: '',
      education2: '',
      recentlyList: [],
      activityList: [],
    }
  },
  created() {
    this.getNews()
    this.getMentorList()
    this.getVips()
  },
  mounted() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    this.clearInv()
  },
  methods: {
    runInv() {
      this.invId = setInterval(() => {
        this.toogle(1)
      }, 3000)
    },
    clearInv() {
      clearInterval(this.invId)
    },
    toogle(n) {
      this.picShowList = []
      console.log(this.picStart + n);
      if (this.picStart + n < -1 * this.picEnd) {
        if (n < 0) {
          this.picShowList = this.picList.slice(0, this.picStart + n)
        } else {
          this.picShowList = this.picList.slice(this.picStart + n)
        }
        if (this.picShowList.length < 5) {
          this.picList
            .slice(0, -3)
            .slice(this.picEnd - this.picShowList.length)
            .map(n => {
              this.picShowList.push(n)
            })
        }
      } else if (this.picStart + n < 0) {
        this.picList.slice(this.picStart + n).map(n => {
          this.picShowList.push(n)
        })
        this.picList.slice(0, 5 + (this.picStart + n)).map(n => {
          this.picShowList.push(n)
        })
      } else if (this.picEnd + n > this.picList.length) {
        this.picList.slice(this.picEnd + n - this.picList.length - 5).map(n => {
          this.picShowList.push(n)
        })
        this.picList.slice(0, this.picEnd + n - this.picList.length).map(n => {
          this.picShowList.push(n)
        })
      } else {
        this.picShowList = this.picList.slice(this.picStart + n, this.picEnd + n)
      }
      this.picStart = this.picStart + n
      this.picEnd = this.picEnd + n
    },
    jump(n) {
      this.toogle(n - 3)
    },
    signUp() {
      let returnEle = document.querySelector('#member')
      if (returnEle) {
        returnEle.scrollIntoView(true)
      } else {
        this.$router.push('/vip-service?h=1')
      }
    },
    handleScroll() {
      let docSrollTop = document.documentElement.scrollTop //文档卷动值
      let clientH = window.innerHeight //视窗大小
      let sectionTop = this.$refs.statistics.offsetTop //动态文字模块距离文档头部的距离
      let sectionH = this.$refs.statistics.offsetHeight
      if (docSrollTop + clientH - sectionTop >= 0 && docSrollTop - sectionTop - sectionH <= 0) {
        this.$refs.count1.start()
        this.$refs.count2.start()
        this.$refs.count3.start()
        this.$refs.count4.start()
        this.$refs.count5.start()
        this.$refs.count6.start()
      } else {
        this.autoplay = false
      }
    },
    moreTutor() {
      this.$router.push('/more-tutor')
    },
    getMentorList() {
      request.post('/in/website/recommend/mentorList', { pageTo: 0, pageSize: 30 }).then(res => {
        this.picList = res.data.items.slice(0,18)
        console.log(this.picList);
        this.picShowList = this.picList.slice(this.picStart, this.picEnd)
      })
    },
    getVips() {
      request.post('/in/website/articleList', { classify: 5 }).then(res => {
        this.ipohelp1 = res.data.items[0]
        this.ipohelp2 = res.data.items[1]
      })
      request.post('/in/website/articleList', { classify: 6 }).then(res => {
        this.financing1 = res.data.items[0]
        this.financing2 = res.data.items[1]
      })
      request.post('/in/website/articleList', { classify: 7 }).then(res => {
        this.business1 = res.data.items[0]
        this.business2 = res.data.items[1]
      })
      request.post('/in/website/articleList', { classify: 8 }).then(res => {
        this.education1 = res.data.items[0]
        this.education2 = res.data.items[1]
      })
    },
    getNews() {
      request.post('/in/website/articleList', { classify: 1 }).then(res => {
        this.recentlyList = res.data.items
      });
      request.post('/in/website/articleList', { classify: 4 }).then(res => {
        this.activityList = res.data.items
      })
    },
    showChat() {
      this.$store.commit('showChat')
    },
    showPopMessage() {
      this.$store.commit('showPopMessage')
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  min-height: 2000px;
  .btn1 {
    min-width: 220px;
    height: 46px;
    background: #ff7b00;
    border-radius: 4px;
    border-color: unset;
    font-size: 18px;
    color: #ffffff;
  }
  .btn1:hover {
    background-color: #e76f00;
  }
  .btn2 {
    width: 218px;
    height: 44px;
    background: rgba(0, 0, 0, 0);
    border: 1px solid #ff7b00;
    box-shadow: 0px 2px 4px 0px rgba(255, 123, 0, 0.25);
    font-size: 18px;
    color: #ff7b00;
    margin-left: 20px;
  }
  .btn2:hover {
    background: rgba(255, 123, 0, 0.1);
  }
  @-webkit-keyframes bounce-up {
    25% {
      -webkit-transform: translateY(10px);
    }
    50%,
    100% {
      -webkit-transform: translateY(0);
    }
    75% {
      -webkit-transform: translateY(-10px);
    }
  }
  .banner {
    height: 793px;
    // background-image: url(../../assets/banner_home.png);
    // background-image: url(https://cdn.sdh365.com/shidonghui/SDH_xuanchuan.mp4);
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    position: relative;
    color: #ffffff;
    text-align: center;
    padding-top: 1px;
    video {
      width: 100%;
      height: 793px;
      object-fit: cover;
    }
    .videoactive {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: rotateY(0deg) rotateX(41deg);
    }
    .videocontent {
      position: absolute;
      width: 100%;
      top: 0;
    }
    h1 {
      margin-top: 285px;
      height: 101px;
      font-size: 72px;
      font-weight: 800;
      letter-spacing: 1px;
    }
    h3 {
      margin-top: 20px;
      height: 42px;
      font-size: 30px;
      font-weight: 700;
      letter-spacing: 0px;
    }
    h5 {
      margin-top: 4px;
      height: 14px;
      font-size: 10px;
      font-weight: 100;
      letter-spacing: 0px;
    }
    h4 {
      margin-top: 21px;
      height: 22px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      letter-spacing: 0px;
    }
  }
  .banner2 {
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 60px;
    position: relative;
    text-align: center;
    .banner2_box{
      display: inline-block;
      width: 1300px;
      text-align: left;
    }
    h2 {
      text-align: center;
      font-size: 36px;
      color: #333333;
      margin-bottom: 95px;
    }
    .left {
      display: inline-block;
      div {
        height: 34px;
        font-size: 24px;
        color: #333333;
      }
      div:hover {
        color: #ff7b00;
      }
      p {
        font-size: 16px;
        color: #666666;
        margin-bottom: 36px;
        margin-left: 19px;
        margin-top: 7px;
      }
    }
    .right {
      width: 537px;
      height: 402px;
      animation: bounce-up 3s linear infinite;
    }
    .statistics {
      // width: 1400px;
      margin-top: 120px;
      padding-left: 270px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 20px;
      .bb {
        margin-bottom: 35px;
        .text::after {
          content: '';
          display: inline-block;
          width: 68px;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.6);
          position: absolute;
          bottom: 0;
          left: 195px;
        }
      }
      .num-box {
        width: 465px;
        height: 136px;
        color: #ffffff;
        text-align: center;
        position: relative;
        .num {
          font-size: 48px;
          margin-bottom: 16px;
        }
        .text {
          font-size: 22px;
        }
      }
      .cb::before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 68px;
        background-color: rgba(255, 255, 255, 0.6);
        position: absolute;
        top: 15px;
        left: 0;
      }
      .cb::after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 68px;
        background-color: rgba(255, 255, 255, 0.6);
        position: absolute;
        top: 15px;
        right: 0;
      }
    }
  }
  .banner3 {
    text-align: center;
    padding-top: 70px;
    height: 793px;
    .banner3_box{
      display: inline-block;
      width: 1200px;
      .banner3_flex{
        display: flex;
        justify-content: space-between;
      }
    }
    h3 {
      font-size: 36px;
      color: #333333;
      margin-bottom: 10px;
    }
    h5 {
      font-size: 20px;
      color: #333333;
      font-weight: 400;
    }
    .box {
      width: 380px;
      height: 420px;
      background: #f7f7f7;
      text-align: center;
      margin-top: 40px;
      .icon {
        width: 60px;
        height: 60px;
        margin-top: 24px;
      }
      .title:hover {
        color: #ff7b00;
      }
      .title {
        font-size: 22px;
        color: #666666;
        position: relative;
      }
      .title::after {
        content: '';
        display: inline-block;
        width: 70px;
        height: 3px;
        background-color: #ff7b00;
        position: absolute;
        bottom: -10px;
        left: calc(50% - 35px);
      }
      .list {
        margin-top: 40px;
        p:hover {
          color: #ff7b00;
        }
        p {
          line-height: 25px;
          font-size: 18px;
          color: #333333;
          margin-bottom: 12px;
        }
      }
    }
    .btn-box {
      margin-top: 50px;
      display: flex;
      justify-content: center;
    }
  }
  .banner4 {
    background-image: url(../../assets/banner_home3.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 144px;
    padding-bottom: 26px;
    text-align: center;
    .box{
      display: inline-block;
    }
  }
  .banner5 {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 30px;
    background: #f7f7f7;
    h3 {
      font-size: 36px;
      color: #333333;
    }
    h5 {
      font-size: 20px;
      color: #333333;
      margin-top: 10px;
      font-weight: 400;
    }
    .box {
      margin-top: 52px;
      display: inline-block;
      width: 1320px;
      .pre,
      .next {
        width: 40px;
        height: 40px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 150px;
        cursor: pointer;
      }
      .pre {
        float: left;
        background-image: url(../../assets/pre.png);
        margin-right: 35px;
      }
      .next {
        float: right;
        background-image: url(../../assets/next.png);
      }
    }
    .photo-box {
      float: left;
      position: relative;
      .name {
        font-size: 26px;
        color: #ff7b00;
        margin-top: 24px;
        margin-bottom: 11px;
      }
      .info {
        width: 420px;
        line-height: 22px;
        font-size: 16px;
        color: #333333;
        text-align: center;
        position: absolute;
        left: -25px;
        display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
        word-break: break-all;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
        -webkit-line-clamp: 7; /** 显示的行数 **/
        overflow: hidden;
      }
    }
    .photo {
      width: 170px;
      height: 170px;
      border-radius: 50%;
      margin: 0 15px;
      margin-top: 85px;
      cursor: pointer;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      transition: 1s linear;
    }
    .photo-active {
      width: 340px;
      height: 340px;
      margin-top: 0;
      transition: 1s linear;
    }
    @keyframes mychange {
      /*mychange是动画的名字上面有用到*/
      0% {
        opacity: 0.2;
        width: 170px;
        height: 170px;
      }
      100% {
        opacity: 1;
        width: 340px;
        height: 340px;
      }
    }
    .btn2 {
      margin-top: 175px;
    }
  }
  .banner6 {
    height: 840px;
    background: #ffffff;
    text-align: center;
    .banner6_box{
      display: inline-block;
      width: 1200px;
    }
    h3 {
      font-size: 36px;
      color: #333333;
      margin-top: 80px;
      text-align: center;
    }
    h5 {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin-top: 15px;
    }
    .right {
      display: inline-block;
      margin-left: 68px;
      margin-top: 70px;
      text-align: left;
      div {
        height: 34px;
        font-size: 24px;
        color: #333333;
      }
      div:hover {
        color: #ff7b00;
      }
      p {
        font-size: 16px;
        color: #666666;
        margin-bottom: 36px;
        margin-left: 19px;
        margin-top: 7px;
      }
      .btn1,
      .btn2 {
        margin-top: 40px;
      }
    }
    .left {
      display: inline-block;
      width: 540px;
      height: 409px;
      margin-bottom: 75px;
      animation: bounce-up 3s linear infinite;
    }
  }
  .banner7 {
    background: #f7f7f7;
    padding-top: 70px;
    padding-bottom: 70px;
    text-align: center;
    .banner7_box{
      display: inline-block;
      width: 1300px;
    }
    h3 {
      font-size: 36px;
      color: #333333;
      text-align: center;
    }
    h5 {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin-top: 15px;
    }
    .left {
      width: 820px;
      float: left;
      text-align: left;
      .title {
        font-size: 26px;
        color: #333333;
        width: 815px;
        padding-bottom: 20px;
        border-bottom: 1px solid #000000;
        margin-top: 40px;
        margin-bottom: 10px;
      }
    }
    .right {
      width: 356px;
      float: right;
      text-align: left;
      .title {
        font-size: 26px;
        color: #333333;
        width: 355px;
        padding-bottom: 20px;
        border-bottom: 1px solid #000000;
        margin-top: 40px;
        margin-bottom: 30px;
      }
      .more {
        float: right;
        margin-top: -73px;
        cursor: pointer;
        color: #666666;
      }
      .type {
        font-size: 20px;
        color: #ff7b00;
        margin-bottom: 13px;
        margin-top: 19px;
      }
      .text:hover {
        color: #ff7b00;
      }
      .text {
        display: block;
        line-height: 22px;
        font-size: 16px;
        color: #333333;
        margin-bottom: 6px;
        width: 340px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .border {
        width: 356px;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 14px;
        margin-bottom: 20px;
      }
    }
    .box {
      width: 385px;
      height: 230px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      padding: 20px 11px 15px 11px;
      position: relative;
      float: left;
      margin-right: 23px;
      margin-top: 20px;
      .text {
        font-size: 16px;
        color: #333333;
      }
      .name {
        font-size: 14px;
        color: #666666;
        position: absolute;
        bottom: 40px;
      }
      .company {
        font-size: 14px;
        color: #666666;
        position: absolute;
        bottom: 15px;
      }
      .photo {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: absolute;
        right: 20px;
        bottom: 10px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .liqingxing {
        background-image: url(../../assets/liqingxing.png);
      }
      .zhufangyi {
        background-image: url(../../assets/zhufangyi.png);
      }
      .fengtianren {
        background-image: url(../../assets/fengtianren.png);
      }
      .xubaohua {
        background-image: url(../../assets/xubaohua.png);
      }
    }
  }
  .banner8 {
    background: #ffffff;
    padding-top: 80px;
    h3 {
      font-size: 36px;
      color: #333333;
      text-align: center;
    }
    h5 {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 38px;
    }
  }
  .banner9 {
    text-align: center;
    background: #f7f7f7;
    padding-top: 48px;
    padding-bottom: 70px;
    .banner9_box{
      display: inline-block;
      width: 1300px;
    }
    h3 {
      font-size: 36px;
      color: #333333;
      text-align: center;
    }
    .left {
      width: 820px;
      float: left;
      text-align: left;
      .title {
        font-size: 26px;
        color: #333333;
        width: 815px;
        padding-bottom: 20px;
        border-bottom: 1px solid #000000;
        margin-top: 40px;
        margin-bottom: 33px;
      }
      .more {
        float: right;
        margin-top: -76px;
        cursor: pointer;
        color: #666666;
      }
      .l {
        float: left;
        .picImg {
          width: 348px;
          height: 261px;
          display: block;
          overflow: hidden;
        }
        .pic {
          width: 348px;
          height: 261px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: #ffffff;
          transition: all 1s;
        }
        .b {
          width: 348px;
          // height: 127px;
          background: #ffffff;
          padding: 10px 13px 12px 15px;
          h5 {
            line-height: 22px;
            font-size: 16px;
            color: #333333;
            margin-bottom: 10px;
          }
          span {
            font-size: 12px;
            color: #666666;
          }
        }
      }
      .l:hover {
        h5 {
          color: #ff7b00;
        }
        .pic {
          transform: scale(1.1);
          -ms-transform: scale(1.1); /* IE 9 */
          -moz-transform: scale(1.1); /* Firefox */
          -webkit-transform: scale(1.1); /* Safari 和 Chrome */
          -o-transform: scale(1.1); /* Opera */
        }
      }
      .r {
        float: right;
        .item {
          display: block;
          width: 460px;
          background: #ffffff;
          margin-bottom: 9px;
          padding: 10px 14px 10px 0px;
          .time {
            display: inline-block;
            width: 83px;
            border-right: 1px solid #eeeeee;
            text-align: center;
            margin-top: 10px;
            .day {
              display: block;
              height: 34px;
              font-size: 24px;
              color: #333333;
            }
            .date {
              height: 14px;
              font-size: 10px;
              color: #333333;
            }
          }
          .article {
            float: right;
            width: 353px;
            h5 {
              line-height: 20px;
              font-size: 14px;
              color: #333333;
              width: 350px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            h5:hover {
              color: #ff7b00;
            }
            span {
              font-size: 10px;
              color: #666666;
              display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
              word-break: break-all;
              text-overflow: ellipsis;
              -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
              -webkit-line-clamp: 3; /** 显示的行数 **/
              overflow: hidden;
            }
          }
        }
      }
    }
    .right {
      float: right;
      width: 356px;
      text-align: left;
      .title {
        font-size: 26px;
        color: #333333;
        width: 355px;
        padding-bottom: 20px;
        border-bottom: 1px solid #000000;
        margin-top: 40px;
        margin-bottom: 30px;
      }
      .more {
        float: right;
        margin-top: -73px;
        cursor: pointer;
        color: #666666;
      }
      .text:hover {
        color: #ff7b00;
      }
      .text {
        display: block;
        line-height: 22px;
        font-size: 16px;
        color: #333333;
        margin-bottom: 20px;
        width: 340px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .btn2 {
      margin-top: 30px;
      display: inline-block;
      line-height: 2.75rem;
      text-align: center;
      border-radius: 4px;
    }
  }
  .banner10 {
    height: 860px;
    background-image: url(../../assets/banner_home4.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 35px;
    text-align: center;
    .banner10_box{
      display: inline-block;
      width: 1200px;
    }
    h3 {
      font-size: 36px;
      color: #333333;
      text-align: center;
    }
    h5 {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin-top: 17px;
    }
    .tab {
      display: inline-block;
      line-height: 74px;
      width: 600px;
      text-align: center;
      font-size: 24px;
      color: #333333;
      margin-top: 35px;
      border-bottom: 1px solid #dddddd;
      cursor: pointer;
    }
    .tab-active {
      color: #ff7b00;
      border-bottom: 1px solid #ff7b00;
    }
    .logo-box {
      width: 1218px;
      height: 445px;
      margin-top: 37px;
      display: flex;
      flex-wrap: wrap;
    }
    .logo {
      width: 189px;
      height: 135px;
      margin: 0 14px 20px 0px;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
      filter: gray;
    }
    .logo:hover {
      -webkit-filter: none;
      -moz-filter: none;
      -ms-filter: none;
      -o-filter: none;
      filter: none;
      filter: none;
    }
    .btn2 {
      margin-top: 48px;
    }
  }
  .banner11 {
    height: 510px;
    background-image: url(../../assets/message_bg.png);
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    // padding-left: 360px;
    padding-top: 118px;
    display: flex;
    justify-content: center;
  }
}
</style>