<template>
  <div v-show="show">
    <div class="chat">
      <div class="title">
        {{ serviceName }}<span class="close" @click="close">x</span><span class="minimize" @click="minimize">-</span>
      </div>
      <div class="scroll content">
        <div v-for="(item, index) in chatRecords" :key="index">
          <div v-if="item.type == 'service'" class="service">
            <div class="avatar" :style="{ backgroundImage: 'url(' + servicePortraitUri + ')' }"></div>
            <div class="name">{{ serviceName }}</div>
            <div class="chat_text">{{ item.content }}</div>
          </div>
          <div v-if="item.type == 'user'" class="user">
            <div class="avatar" :style="{ backgroundImage: userAvatarUrl }"></div>
            <div class="name">我</div>
            <div class="chat_text">{{ item.content }}</div>
          </div>
        </div>
      </div>
      <div>
        <el-input class="scroll" type="textarea" v-model="message" rows="4" placeholder="说点什么吧..." style="padding-top: 10px"></el-input>
        <el-button class="btn send" :class="message.length == 0 ? 'disabledBtn' : ''" @click="sendMessage">发送</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
import chat from '@/utils/chat'
export default {
  data() {
    return {
      appKey: 'mgb7ka1nmyphg',
      rongToken: '',
      message: '',
      RongIMLib: null,
      servicePortraitUri: '',
      serviceUserId: '',
      serviceName: '',
      userInfoId: '',
      userAvatarUrl: 'url(' + require('../assets/userAvatar.png') + ')',
      chatRecords: [],
    }
  },
  computed: {
    show() {
      return this.$store.state.showChat
    },
  },
  // mounted() {
  //   this.timer()
  // },
  // destroyed() {
  //   this.timer()
  //   clearInterval(this.timer())
  // },
  async created() {
    await this.getToken()
    this.startInit()
  },
  methods: {
    // timer() {
    //   let that = this
    //   setInterval(() => {
    //     that.$store.state.showChat = true
    //   }, 30000)
    // },
    async getToken() {
      await request.post('in/website/rongchat').then(res => {
        this.rongToken = res.data.rongToken
        this.serviceUserId = res.data.serviceUserId
        this.servicePortraitUri = res.data.servicePortraitUri
        this.serviceName = res.data.serviceName
      })
    },
    startInit() {
      var params = {
        appKey: this.appKey,
        token: this.rongToken,
      }
      var callbacks = {
        getRongIMLib: RongIMLib => {
          this.RongIMLib = RongIMLib
        },
        getCurrentUser(userInfo) {
          this.userInfoId = userInfo.userId
          // const userId = getCookie('uId')
          // document.titie = "链接成功；userid=" + userInfo.userId;
        },
        // 收到融云的最新消息
        receiveNewMessage: message => {
          console.log(this.chatRecords, message)
          if (message.messageType == 'TextMessage') {
            this.chatRecords.push({
              type: 'service',
              content: message.content.content,
            })
          }
        },
      }
      chat.init(params, callbacks)
    },
    sendMessage() {
      if (this.message.length == 0) return
      var msg = new this.RongIMLib.TextMessage({ content: this.message })
      var conversationType = this.RongIMLib.ConversationType.PRIVATE // 单聊, 其他会话选择相应的会话类型即可
      var RongIMClient = this.RongIMLib.RongIMClient
      RongIMClient.getInstance().sendMessage(conversationType, this.serviceUserId, msg, {
        onSuccess: message => {
          // message 为发送的消息对象并且包含服务器返回的消息唯一 Id 和发送消息时间戳
          console.log(message)
          this.chatRecords.push({
            type: 'user',
            content: message.content.content,
          })
          this.message = ''
        },
        onError: function (errorCode) {
          var info = ''
          switch (errorCode) {
            case this.RongIMLib.ErrorCode.TIMEOUT:
              info = '超时'
              break
            case this.RongIMLib.ErrorCode.UNKNOWN:
              info = '未知错误'
              break
            case this.RongIMLib.ErrorCode.REJECTED_BY_BLACKLIST:
              info = '在黑名单中，无法向对方发送消息'
              break
          }
          console.log('发送失败: ' + info + errorCode)
        },
      })
    },
    minimize() {
      this.$store.commit('hideChat')
    },
    close() {
      this.$store.commit('hideChat')
    },
  },
}
</script>

<style lang="less" scoped>
.chat {
  width: 378px;
  height: 520px;
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 100px;
  bottom: 0;
  z-index: 9999;
  .title {
    line-height: 60px;
    background: #ff7b00;
    font-size: 20px;
    color: #ffffff;
    padding-left: 20px;
    .minimize,
    .close {
      float: right;
      font-size: 27px;
      margin-right: 30px;
      margin-top: -5px;
      cursor: pointer;
    }
  }
  .content {
    height: 311px;
    background: #f7f7f7;
    border: 1px solid #eeeeee;
    overflow-y: auto;
    padding-top: 8px;
    .chat_text {
      display: inline-block;
      max-width: 240px;
      overflow-wrap: anywhere;
      // line-height: 40px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 4px;
      padding: 8px 13px;
      margin-top: 35px;
      text-align: left;
    }
    .avatar {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-position: center;
      background-size: contain;
    }
    .name {
      display: inline-block;
      line-height: 40px;
      margin-right: 8px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
    }
    .user {
      float: right;
      width: 100%;
      .avatar,
      .name,
      .chat_text {
        float: right;
      }
      .chat_text {
        margin-right: -20px;
      }
      .avatar {
        margin-right: 20px;
      }
    }
    .service {
      float: left;
      width: 100%;
      .avatar,
      .name {
        float: left;
      }
      .name {
        margin-left: 8px;
      }
      .chat_text {
        float: left;
        margin-top: 45px;
        margin-left: -110px;
      }
      .avatar {
        margin-left: 20px;
      }
    }
  }
  .send {
    display: inline-block;
    padding: 8px 15px;
    background: #ff7b00;
    border-radius: 4px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    position: absolute;
    bottom: 16px;
    right: 16px;
    border: none;
  }
  .disabledBtn {
    background-color: #ffa24c;
    cursor: default;
  }
}
</style>
<style lang="less">
.chat {
  textarea {
    resize: none;
    outline: none;
    border: none;
  }
}
</style>