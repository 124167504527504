<template>
  <div class="vip-service" id="vip-service">
    <Swiper @signUp="signUp"></Swiper>
    <div class="list">
      <div class="box">
        <div class="info">
          <h3>01</h3>
          <span class="title">师董会APP线上权益</span
          ><span class="highlight">(免费获得7个)</span>
          <div class="text">
            可在师董会APP中使用积分参与“名师问答”，向企业家、投资家、科学
            家等平台导师发起“提问”或“聊天”；可使用师董直播、在线路演、每天
            学点、紧急求助、在线交流、查找资源等平台功能，通过线上一站式解
            决学习交流、营销曝光、资源对接等问题。
          </div>
        </div>
        <div class="vessel">
          <div
            class="pic"
            :style="{
              backgroundImage: 'url(https://cdn.sdh365.com/ow/vip_pic1.png)',
            }"
          ></div>
        </div>
      </div>
      <div class="box">
        <div class="vessel">
          <div
            class="pic"
            :style="{
              backgroundImage: 'url(https://cdn.sdh365.com/ow/vip_pic2.png)',
            }"
          ></div>
        </div>
        <div class="info">
          <h3>02</h3>
          <span class="title">商道中国游学课程</span
          ><span class="highlight">(一年免费参加6次)</span>
          <div class="text">
            商道中国通过一年6次（全国巡回），每期为2天，其中1.5天学习，
            0.5天参观标杆企业，会员企业家走出去跟导师面对面学习企业文化、
            企业品牌IP、业绩倍增、资本强化、模式重塑、财务风险管控，并对
            建党百年提供红色教育等系统课程，结交人脉，参观标杆企业，取长
            补短，掌握如何让员工自动自发，企业做大做强、基业长青的秘决。
          </div>
        </div>
      </div>
      <div class="box">
        <div class="info">
          <h3>03</h3>
          <span class="title">企业家大型峰会</span
          ><span class="highlight">(一年免费参加1次)</span>
          <div class="text">
            通过一年一次企业家大型峰会，会员企业家论坛现场聆听最新资讯，解
            读国家最新政策等，同时有机会和大咖同台增加个人IP和企业IP，做一
            名新时代先知先觉的企业家。
          </div>
        </div>
        <div class="vessel">
          <div
            class="pic"
            :style="{
              backgroundImage: 'url(https://cdn.sdh365.com/ow/vip_pic3.png)',
            }"
          ></div>
        </div>
      </div>
      <div class="box">
        <div class="vessel">
          <div
            class="pic"
            :style="{
              backgroundImage: 'url(https://cdn.sdh365.com/ow/vip_pic4.png)',
            }"
          ></div>
        </div>
        <div class="info">
          <h3>04</h3>
          <span class="title">师董展览交易会</span
          ><span class="highlight">(一年免费参加4次)</span>
          <div class="text">
            一年4次师董展览交易会，通过师董会APP线上同步直播+线下活动现
            场，师董会导师教您讲解商品、项目招商，让会员企业之间更了解，
            让做生意变得更简单，同时进行产品展览交易会：产品招商合作、项
            目融资路演、产业跨界合作、师董大集等。
          </div>
        </div>
      </div>
      <div class="box">
        <div class="info">
          <h3>05</h3>
          <span class="title">钻石会员专属权益</span
          >
          <!-- <span class="highlight">(一年免费参加6次)</span> -->
          <div class="text">
            方案1：针对会员企业一对一微咨询<br/>
            钻石会员把企业经营过程中会遇到的痛点难点反馈给师董会客服人员，由师董会客服人员提供给师董微咨询团队收集、电话调研及匹配，匹配合适的咨询导师到企业与高管面对面咨询（1天），出谋划策，并由咨询导师根据和企业沟通结果进行方案共建，专家诊断后，线上出示问题解决指导意见书，并进行1年的跟踪指导服务。部分顾问服务和导师增值服务，需另外付费。
          </div>
        </div>
        <div class="vessel">
          <div
            class="pic"
            :style="{
              backgroundImage: 'url(https://cdn.sdh365.com/ow/vip_pic5.png)',
            }"
          ></div>
        </div>
      </div>
      <div class="box">
        <div class="vessel">
          <div
            class="pic"
            :style="{
              backgroundImage: 'url(https://cdn.sdh365.com/ow/vip_pic6.png)',
            }"
          ></div>
        </div>
        <div class="info">
          <h3>06</h3>
          <span class="title">钻石会员专属权益</span
          >
          <!-- <span class="highlight">(一年免费参加3次)</span> -->
          <div class="text">
            方案2：企业建档服务<br/>
            内容包括基础建档、口碑优化、媒体声量、物料准备等，可帮助企业通过百科、知科、问答等全网宣传扩大企业知名度、宣传力度，通过与师董会大型论坛的企业大咖导师同台路演活动事件打造企业IP、企业老板的个人IP。同时在百家媒体资源平台发放，便于企业卖产业、招商、融资等。（方案1、方案2二选一）
          </div>
        </div>
      </div>
      <div class="box">
        <div class="info">
          <h3>07</h3>
          <span class="title">门徒会员专属权益</span>
          <div class="text">
            包含白金会员所有会员权益；师父上门为门徒企业指导3次；门徒企业会员与导师建立终身师徒关系，可随时联系导师，导师第一时间回复；优先参与弟子帮扶计划；可跟随师父看项目、投资，并获得一次随师参加高端商务活动的机会；可参加武当山养生1次；每年可参与1次专属私董会；师董展销会时，门徒会员项目优先安排展示1次；线下项目路演时，门徒会员项目优先安排1次（8分钟）。
          </div>
        </div>
        <div class="vessel">
          <div
            class="pic"
            :style="{
              backgroundImage: 'url(https://cdn.sdh365.com/ow/vip_pic1.png)',
            }"
          ></div>
        </div>
      </div>
      <div class="btns">
        <div class="btn1" @click="signUp">立即报名</div>
        <div class="btn2" @click="showChat">在线咨询</div>
      </div>
    </div>
    <div class="banner7">
      <h3>服务案例</h3>
      <h5>
        成功为会员提供包括扶持上市、投融资、企业管理、业务资源对接、教育医疗等多个方面的深度服务
      </h5>
      <div class="banner7_box">
        <div class="left">
          <div class="title">会员好评</div>
          <div class="box">
            <div class="text">
              我是会员李庆星，在师董会主席胡海平的帮助与投
              资中，我司发展迅猛。在2016年5月25日，我司决
              策者在“新三板”正式挂牌（证券代码：837621），
              同时决策者成为中国新三板首家挂牌上市的商务会 议公司。
            </div>
            <div class="name">李庆星</div>
            <div class="company">上海决策者经济顾问股份有限公司</div>
            <div class="photo liqingxing"></div>
          </div>
          <div class="box">
            <div class="text">
              我是会员朱芳义，一直为传贝智慧支付项目的资金而
              焦灼不已，于是我拜访了师董会主席胡海平。在胡主
              席的战略对接与引荐后，我成功获得500万融资，并
              得到与家电卖场合作的机会，更对接了省家电协会。
            </div>
            <div class="name">朱芳义</div>
            <div class="company">中国乌镇壹传科技公司</div>
            <div class="photo zhufangyi"></div>
          </div>
          <div class="box">
            <div class="text">
              我是钻石会员冯天仁，在一次线下游学活动中，我
              通过师董会客服介绍认识了白金会员岳文浩。经过
              几次沟通交流后达成合作，我们共同出资成立上海
              忆链物联网公司，并牵头完成LNG多式联运物流方案。
            </div>
            <div class="name">冯天仁</div>
            <div class="company">上海忆链物联网公司</div>
            <div class="photo fengtianren"></div>
          </div>
          <div class="box">
            <div class="text">
              我是徐宝华，师董会主席胡海平投资我公司的烟台惠
              通网络技术有限公司的无线充电项目。如今，该公司
              发展如火如荼，回报翻了10倍，已成为行业翘楚。
            </div>
            <div class="name">徐宝华</div>
            <div class="company">烟台惠通网络技术有限公司</div>
            <div class="photo xubaohua"></div>
          </div>
        </div>
        <div class="right">
          <div class="title">服务案例</div>
          <div class="more">
            <router-link to="/membership">查看更多></router-link>
          </div>
          <div class="border">
            <div class="type">扶持上市</div>
            <router-link
              class="text"
              :to="`/news-detail?id=${ipohelp1.articleId}`"
              >· {{ ipohelp1.title }}</router-link
            >
            <router-link
              class="text"
              :to="`/news-detail?id=${ipohelp2.articleId}`"
              >· {{ ipohelp2.title }}</router-link
            >
          </div>
          <div class="border">
            <div class="type">投融资</div>
            <router-link
              class="text"
              :to="`/news-detail?id=${financing1.articleId}`"
              >· {{ financing1.title }}</router-link
            >
            <router-link
              class="text"
              :to="`/news-detail?id=${financing2.articleId}`"
              >· {{ financing2.title }}</router-link
            >
          </div>
          <div class="border">
            <div class="type">业务对接</div>
            <router-link
              class="text"
              :to="`/news-detail?id=${business1.articleId}`"
              >· {{ business1.title }}</router-link
            >
            <router-link
              class="text"
              :to="`/news-detail?id=${business2.articleId}`"
              >· {{ business2.title }}</router-link
            >
          </div>
          <div>
            <div class="type">教育医疗</div>
            <router-link
              class="text"
              :to="`/news-detail?id=${education1.articleId}`"
              >· {{ education1.title }}</router-link
            >
            <router-link
              class="text"
              :to="`/news-detail?id=${education2.articleId}`"
              >· {{ education2.title }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="vip">
      <h3>会员列传</h3>
      <h5>
        展示会员企业创立、成长、发展等过程，通过创业故事、产品介绍、企业文化与管理理念多方面的展示，
        加深会员之间的相互了解，也让同行者和后来者得以借鉴、参考和学习。
      </h5>
      <div class="flex">
        <div class="box">
          <div class="vipImg"><div class="pic vip1"></div></div>
          <div class="title">顺天取势 厚德重贤</div>
          <div class="text">访廊坊市海创农业发展有限公司副董事长刘宝海</div>
        </div>
        <div class="box">
          <div class="vipImg"><div class="pic vip2"></div></div>
          <div class="title">会员企业上榜全国防火涂料供应企业30强</div>
          <div class="text">
            师董会钻石会员刘清良企业福建南烽防火科技有限公司上榜2017-
            2019年全国范围内防火涂料排名30强,排名十九!
          </div>
        </div>
        <div class="box">
          <div class="vipImg"><div class="pic vip3"></div></div>
          <div class="title">师董会导师王为钢先生投资金斗云辉</div>
          <div class="text">
            近日，行业大咖、业内顶级专家、诺德董事、师董会导师王为钢先生个人投资师董会产业互联网与区块链分会主席、师董会导师、师董会钻石会员齐洪庆企业金斗云辉600万元
          </div>
        </div>
      </div>
      <h3 class="bh" id="member">加入师董会，成为千亿上市产业共同体的成员</h3>
    </div>
    <div class="member clear-fix">
      <div class="title"></div>
      <Member class="m"></Member>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import Member from "../../components/member";
import Swiper from "../../components/swiper";
export default {
  name: "Vip-service",
  components: {
    Member,
    Swiper,
  },
  data() {
    return {
      ipohelp1: "",
      ipohelp2: "",
      financing1: "",
      financing2: "",
      business1: "",
      business2: "",
      education1: "",
      education2: "",
    };
  },
  created() {
    this.getVips();
    console.log(this.$route.query.h);
    if (this.$route.query.h) {
      setTimeout(function () {
        let returnEle = document.querySelector("#member");
        console.log(returnEle);
        if (returnEle) {
          returnEle.scrollIntoView(true);
        }
      }, 500);
    }
  },
  methods: {
    getVips() {
      request.post("/in/website/articleList", { classify: 5 }).then((res) => {
        this.ipohelp1 = res.data.items[0];
        this.ipohelp2 = res.data.items[1];
      });
      request.post("/in/website/articleList", { classify: 6 }).then((res) => {
        this.financing1 = res.data.items[0];
        this.financing2 = res.data.items[1];
      });
      request.post("/in/website/articleList", { classify: 7 }).then((res) => {
        this.business1 = res.data.items[0];
        this.business2 = res.data.items[1];
      });
      request.post("/in/website/articleList", { classify: 8 }).then((res) => {
        this.education1 = res.data.items[0];
        this.education2 = res.data.items[1];
      });
    },
    signUp() {
      let returnEle = document.querySelector("#member");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    showChat() {
      this.$store.commit("showChat");
    },
  },
};
</script>

<style lang="less" scoped>
.vip-service {
  .carousel {
    height: 620px;
  }
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  .banner {
    height: 100%;
    width: 100%;
  }
  .list {
    width: 1200px;
    padding-top: 100px;
    padding-bottom: 70px;
    margin: 0 auto;
    .box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px;
    }
    .info {
      display: inline-block;
      width: 540px;
      h3 {
        width: 600px;
        line-height: 75px;
        font-size: 72px;
        font-family: HuXiaoBo;
        color: #333333;
        position: relative;
        margin-bottom: 110px;
      }
      h3::after {
        content: "";
        display: inline-block;
        width: 2px;
        height: 87px;
        background-color: #333333;
        position: absolute;
        left: 25px;
        top: 80px;
      }
      .title {
        line-height: 27px;
        font-size: 26px;
        font-family: HuXiaoBo;
        color: #333333;
      }
      .highlight {
        font-size: 24px;
        color: #ffb400;
        margin-left: 12px;
      }
      .text {
        display: inline-block;
        width: 561px;
        line-height: 25px;
        font-size: 18px;
        color: #666666;
        margin-top: 30px;
      }
    }
    .vessel {
      width: 600px;
      height: 400px;
      display: block;
      overflow: hidden;
    }
    .pic {
      display: inline-block;
      width: 600px;
      height: 400px;
      background-position: center;
      background-size: contain;
      transition: all 1s;
    }
    .pic:hover {
      transform: scale(1.1);
      -ms-transform: scale(1.1); /* IE 9 */
      -moz-transform: scale(1.1); /* Firefox */
      -webkit-transform: scale(1.1); /* Safari 和 Chrome */
      -o-transform: scale(1.1); /* Opera */
    }
    .btns {
      display: flex;
      justify-content: center;
      .btn1,
      .btn2 {
        width: 220px;
        height: 46px;
        border-radius: 4px;
        text-align: center;
        line-height: 46px;
        font-family: "PingFang SC", "PingFang SC-Bold";
        font-size: 18px;
        cursor: pointer;
      }
      .btn1 {
        background-color: #ff7b00;
        color: #ffffff;
      }
      .btn1:hover {
        background: #e76f00;
      }
      .btn2:hover {
        background: rgba(255, 123, 0, 0.1);
      }
      .btn2 {
        border: 1px solid #ff7b00;
        color: #ff7b00;
        margin-left: 20px;
      }
    }
  }
  .banner7 {
    background: #f7f7f7;
    padding-top: 70px;
    padding-bottom: 70px;
    text-align: center;
    .banner7_box{
      display: inline-block;
      width: 1300px;
      text-align: left;
    }
    h3 {
      font-size: 36px;
      color: #333333;
      text-align: center;
    }
    h5 {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin-top: 15px;
    }
    .left {
      width: 820px;
      float: left;
      .title {
        font-size: 26px;
        color: #333333;
        width: 815px;
        padding-bottom: 20px;
        border-bottom: 1px solid #000000;
        margin-top: 40px;
        margin-bottom: 10px;
      }
    }
    .right {
      display: inline-block;
      width: 356px;
      margin-left: 30px;
      .title {
        font-size: 26px;
        color: #333333;
        width: 355px;
        padding-bottom: 20px;
        border-bottom: 1px solid #000000;
        margin-top: 40px;
        margin-bottom: 30px;
      }
      .more {
        float: right;
        margin-top: -73px;
        cursor: pointer;
        color: #666666;
      }
      .type {
        font-size: 20px;
        color: #ff7b00;
        margin-bottom: 13px;
        margin-top: 19px;
      }
      .text {
        display: block;
        line-height: 22px;
        font-size: 16px;
        color: #333333;
        margin-bottom: 6px;
        width: 340px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .border {
        width: 356px;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 14px;
        margin-bottom: 20px;
      }
    }
    .box {
      width: 385px;
      height: 230px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      padding: 20px 11px 15px 11px;
      position: relative;
      float: left;
      margin-right: 23px;
      margin-top: 20px;
      .text {
        font-size: 16px;
        color: #333333;
      }
      .name {
        font-size: 14px;
        color: #666666;
        position: absolute;
        bottom: 40px;
      }
      .company {
        font-size: 14px;
        color: #666666;
        position: absolute;
        bottom: 15px;
      }
      .photo {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: absolute;
        right: 20px;
        bottom: 10px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .liqingxing {
        background-image: url(../../assets/liqingxing.png);
      }
      .zhufangyi {
        background-image: url(../../assets/zhufangyi.png);
      }
      .fengtianren {
        background-image: url(../../assets/fengtianren.png);
      }
      .xubaohua {
        background-image: url(../../assets/xubaohua.png);
      }
    }
  }
  .vip {
    padding-top: 80px;
    padding-bottom: 47px;
    background: #ffffff;
    text-align: center;
    h3 {
      font-size: 36px;
      color: #333333;
      text-align: center;
    }
    h5 {
      display: inline-block;
      width: 920px;
      line-height: 28px;
      font-size: 20px;
      color: #666666;
      margin-top: 16px;
      font-weight: 500;
    }
    .flex {
      display: flex;
      justify-content: center;
      margin-top: 38px;
      .box {
        display: inline-block;
        width: 377px;
        margin-right: 15px;
        .vipImg{
          width: 377px;
          height: 337px;
          overflow: hidden;
        }
        .pic {
          width: 377px;
          height: 337px;
          position: relative;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          transition: all 1s;
        }
        .vip1 {
          background-image: url(https://cdn.sdh365.com/ow/vipPerson1.png);
        }
        .vip2 {
          background-image: url(https://cdn.sdh365.com/ow/vipPerson2.png);
        }
        .vip3 {
          background-image: url(https://cdn.sdh365.com/ow/vipPerson3.png);
        }
        .title {
          margin-top: 10px;
          line-height: 30px;
          font-size: 22px;
          font-weight: 700;
          color: #333333;
          text-align: left;
          width: 375px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .pic::after {
          content: "";
          // display: inline-block;
          width: 377px;
          height: 1px;
          background-color: #ffd87a;
          position: absolute;
          left: 0;
          top: 385px;
        }
        .text {
          text-align: left;
          line-height: 22px;
          font-size: 16px;
          color: #666666;
          margin-top: 18px;
          display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
          word-break: break-all;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
          -webkit-line-clamp: 2; /** 显示的行数 **/
          overflow: hidden;
        }
      }
      .box:hover {
        .title{
          color: #ff7b00;
        }
        .pic {
          transform: scale(1.1);
          -ms-transform: scale(1.1); /* IE 9 */
          -moz-transform: scale(1.1); /* Firefox */
          -webkit-transform: scale(1.1); /* Safari 和 Chrome */
          -o-transform: scale(1.1); /* Opera */
        }
      }
    }
    .bh {
      margin-top: 95px;
    }
  }
  .member {
    background-color: #f7f7f7;
    padding-top: 1px;
    .m {
      // margin-top: 40px;
      margin-left: 375px;
      width: 577px;
      float: right;
    }
    .title {
      float: right;
      width: 900px;
      height: 680px;
      background-image: url(https://cdn.sdh365.com/ow/member.png);
      background-position: center;
      background-size: cover;
      background-color: #333333;
    }
  }
}
</style>
<style>
.el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
}
</style>