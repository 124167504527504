import Vue from 'vue'
import App from './App'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import '@/styles/index.css' // global css

import store from './store.js'
import router from "@/router/router.js"

// import '@/assets/font/font.scss'; // 引入字体样式

Vue.use(ElementUI)

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 跳转后返回顶部
router.afterEach(() => {
    window.scrollTo(0, 0);
});
import '@/rem.js'

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})