<template>
  <div class="boxs" v-show="show">
    <div class="closebtn" @click="closebtn('data')">
      <img src="../assets/closebtn.png" alt="" />
    </div>
    <div class="border">
      <div class="txt">
        <img src="../assets/poptitle.png" alt="" />
      </div>
      <el-form :inline="true" :model="data" ref="data">
        <el-form-item label="您是谁：" prop="name">
          <el-input
            v-model="data.name"
            class="name"
            placeholder="请输入您的姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="怎么联系您：" prop="mobile">
          <el-input
            v-model="data.mobile"
            class="tel"
            placeholder="请输入您的电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="留下您想说的话：" prop="content">
          <el-input
            v-model="data.content"
            class="message"
            type="textarea"
            placeholder="请输入您的留言"
            rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="bb">
        <span class="btn" type="primary" @click="submit">提交留言</span>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { Message } from "element-ui";
export default {
  name: "Message",
  data() {
    return {
      data: {
        name: "",
        mobile: "",
        content: "",
      },
    };
  },
  computed: {
    show() {
      return this.$store.state.showPopMessage;
    },
  },
  methods: {
    closebtn(name) {
      this.$refs[name].resetFields();
      this.$store.commit("hidePopMessage");
    },
    submit() {
      if (this.data.name == "") {
        Message({
          message: "请输入您的称呼",
          type: "warning",
          duration: 5 * 1000,
        });
        return;
      }
      if (this.data.mobile == "") {
        Message({
          message: "请输入您的电话",
          type: "warning",
          duration: 5 * 1000,
        });
        return;
      }
      request.post("/in/website/leaveword", this.data).then(() => {
        this.data.name = "";
        this.data.mobile = "";
        this.content = "";
        Message({
          message: "感谢您的留言",
          type: "success",
          duration: 5 * 1000,
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.boxs {
  z-index: 999;
  position: fixed;
  top: 100px;
  left: 50%;
  margin-left: -189px;
  width: 378px;
  height: 500px;
  background-color: #ffffff;
  border-radius: 10px;
  // border: 1px solid #ff7b00;
  padding: 20px 30px 41px;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

  .closebtn {
    width: 22px;
    height: 22px;
    cursor: pointer;
    top: 21px;
    right: 25px;
    position: absolute;
    img {
      width: 100%;
    }
  }
  .border {
    border-radius: 10px;
    background-color: #ffffff;
    width: 100%;

    .txt {
      width: 189px;
      height: 40px;
      margin: 0 auto 20px;
      img {
        width: 100%;
      }
    }
    .bb {
      text-align: center;
      position: relative;
      margin-top: 18px;
    }
  }
  .name {
    // width: 430px;
    height: 32px;
    // border: 1px solid #eeeeee;
    border-radius: 4px;
  }
  .tel {
    // width: 353px;
    height: 32px;
    // border: 1px solid #eeeeee;
    border-radius: 4px;
  }
  .message {
    display: inline-block;
    // width: 895px;
    height: 103px;
    border: 1px solid #dddddd;
    border-radius: 4px;
  }
  .btn {
    display: block;
    cursor: pointer;
    width: 306px;
    height: 40px;
    line-height: 40px;
    background: #ff7b00;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
}
</style>
<style lang="less">
.boxs {
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-form-item__label {
    min-width: 100px;
    font-size: 16px;
    color: #333333;
    text-align: left;
    padding-right: 0;
    font-weight: 700;
  }
  .el-form-item__content {
    width: 306px;
  }
  .el-input {
    width: 306px;
    height: 32px;
    line-height: 32px;
  }
  .el-input__inner {
    width: 306px;
    height: 32px;
    line-height: 32px;
    // background: #f9f9f9;
    border: 1px solid #ddd;
  }
  .el-textarea__inner {
    resize: none;
    border: none;
    // background: #f9f9f9;
  }
  input::-webkit-input-placeholder {
    /*WebKit browsers*/
    color: #999;
  }

  input::-moz-input-placeholder {
    /*Mozilla Firefox*/
    color: #999;
  }

  input::-ms-input-placeholder {
    /*Internet Explorer*/
    color: #999;
  }
  textarea::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #999;
  }
  textarea:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #999;
  }
  textarea::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #999;
  }
  textarea::-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #999;
  }
}
</style>