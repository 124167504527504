<template>
  <div class="ipo-service" id="ipo-service">
    <img class="banner" src="../../assets/energy_banner1.png" />
    <div class="banner2">
        <div class="title">晖阳（贵州）新能源材料有限公司介绍</div>
        <div class="text">晖阳（贵州）新能源材料有限公司是由纳斯达克上市公司师董会（EPOW.US）与黔西南州政府及投资平台等共同投资设立，公司注册资金32140.8万元，是一家专业从事锂离子电池负极材料研发、生产与销售的技术企业。</div>
        <div class="text">公司在贵州黔西南州义龙新区投资建设年产10万吨产能锂离子负极材料项目，并在辽宁海城已经建立研发和生产基地，与多个产业链上下游龙头企业建立的深度的研发合作关系，主要产品包括人造石墨，天然石墨，复合石墨及软炭、硬炭、硅碳等负极材料。产品广泛应用于移动电子及数码设备、电动车、储能等领域。</div>
        <div class="text">核心团队由行业资深人士组建，创始人胡海平，是中国负极材料国产化的开拓者和先驱，1999年开始开拓锂电材料领域，培养一批行业领域内的技术、管理、资本佼佼者，曾获中国十大杰出CEO、中国十大新能源人物称号。董事总经理杜辉玉，锂离子电池及材料行业多个上市公司从业20年，拥有丰富的管理经营、技术研究、市场开发经验，开拓了系列多个受市场欢迎的新一代的负极产品。总工程师张殿浩担任30多年的部级研究院、上市公司高级工程师，中间相碳微球（CMS）专利的首席发明人，从事碳素领域34年，从事该行业以来他所带领的团队及个人获得国家专利及各种荣誉证书达几十项，培养了一大批该领域高端技术人才，为中国锂离子电池负极材料发展壮大及走出国门做出了巨大贡献。晖阳新能源依托专利及技术储备、合理的梯度人才配备、多年行业深耕市场优势、发挥上市公司资本支持，以一体化的研发生产技术和优质产品满足市场需求为核心竞争力，力争成为推动产业整合与发展的领导者。</div>
    </div>
    <div class="banner3">
        <div class="title"><span style="color:#FE6101">新能源</span>生产基地布局</div>
        <img class="map" src="../../assets/energy_map.png" />
    </div>
    <div class="banner4">
        <el-carousel arrow="never">
            <el-carousel-item v-for="item in carouselList" :key="item" class="carousel-item">
               <div>
                   <span class="title">产品中心</span>
                   <div class="name">{{item.name}}</div>
                   <div class="text">{{item.text}}</div>
               </div>
               <img class="mirror" src="../../assets/pic_energy.png" />
            </el-carousel-item>
        </el-carousel>
    </div>
    <div class="banner5">
        <div class="title"><span style="color:#FE6101">核心</span>创始团队</div>
        <el-carousel type="card" arrow="never">
            <el-carousel-item class="carousel-item">
                <img class="left" src="../../assets/energy_person1.png">
                <div class="right">
                    <p class="name">胡海平</p>
                    <div class="text">
                        <p>• 2018光荣浙商 中国十大经济影响力人物</p>
                        <p>• 1996-2017杉杉集团、杉杉控股 总裁、CEO</p>
                        <p>董事局副主席 2010年度中国十大杰出CEO</p>
                        <p>十大新能源人物、科创产业与资本运营</p>
                        <p>• 舟山中学保送进入浙江大学混合班研究生毕</p>
                        <p>业后以综合成绩第一进入P&G，现任浙江大</p>
                        <p>• 北京浙商总会常务副会长</p>
                        <p>• 美中企业家商会高级顾问、上海商会会长 • 浙商总会互联网专业委员会副主席</p>
                        <p>• 上海市工商联执委、浦东工商联副主席</p>
                        <p>• 浙江大学上海校友会会长</p>
                        <p>• 第五届光荣浙商（2018）</p>
                        <p>• 中国十大经济影响力人物（2018）</p>
                        <p>• 中国十大新能源人物（2010）</p>
                        <p>• 中国十大杰出CEO（2010）</p>
                    </div>
                </div>
            </el-carousel-item>
            <el-carousel-item class="carousel-item">
                <img class="left" src="../../assets/energy_person2.png">
                <div class="right">
                    <p class="name">杜辉玉</p>
                    <div class="text">先后从事锂电池材料行业质量管理、采购管理、生产 调度、销售管理工作。2008年担任上海杉杉科技有限 公司总经理，期间公司业绩保持年30%以上增长，成 功开发了LG、SDI、SK、索尼、BYD、冠宇等国内外 大客户。2020年加入广东某锂电池负极材料公司任副 总裁, 分管销售和研发工作, 同时开发了358容量的CNT、 石墨烯快充产品。</div>
                </div>
            </el-carousel-item>
            <el-carousel-item class="carousel-item">
                <img class="left" src="../../assets/energy_person3.png">
                <div class="right">
                    <p class="name">张殿浩</p>
                    <div class="text">中间相碳微球专利首席发明人，广东省扬帆计划带头人。 从事碳素领域34年，国内石墨类锂离子电池负极材料技 术领域领军人物。曾担任冶金部鞍山热能研究院高级工 程师、特种碳素研究组长，上海杉杉科技第一任总工程 师, 杉杉硕能复合材料有限公司总工程师，海城申合科 技有限公司总工程师。</div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
    <div class="banner6">
        <div class="title"><span style="color:#FE6101">技术</span>开发布局</div>
        <img class="img" src="../../assets/energy_banner3.png" />
    </div>
    <img class="banner7" src="../../assets/energy_banner2.png" />
  </div>
</template>

<script>
export default {
  name: 'New-energy',
  data(){
      return {
          carouselList: [
            {
                name:"石墨负极材料",
                text:"将石墨烯与溶剂制成浆料A，以粘结剂与溶剂混合制成混合液B，将石墨与A、B混合后，经喷雾干燥后，再经炭化处理。 制成 石墨烯改性负极材料。该材料电容量可达360~390mAh/g。"
            },
            {
                name:"Sn/C复合负极材料",
                text:" 以Sn（OBU）4为原料，在乙醇中制成凝胶A，酚醛树脂溶于乙醇制成溶液B，将石墨与A、B混合制成混合液。引发水 解缩合和酚醛树脂的逐步固化，得到凝胶，经过热处理和炭化后，得到Sn/C复合材料。 该材料的电容量可达 400~450mAh/g。目前，还需要在提高首次效率上改进。"
            },
            {
                name:"Si/C复合负极材料",
                text:"以Si或SiO研磨成纳米颗粒，分散在乙醇溶剂中制成混合液A，以粘结剂溶于溶剂中，制成混合液B，将石墨与A、B混合， 经气流干燥，再经碳化处理，制成Si/C复合负极材料。该材料电容量可达400~500mAh/g。目前，市场上虽然已有千吨 级产品，正处于上升势头，但还需在提高循环寿命上进一步改进。"
            }
          ]
      }
  },
  methods: {
      
  }
}
</script>

<style lang="less" scoped>
.ipo-service {
  .banner {
    height: 1060px;
    width: 100%;
  }
  .banner2 {
    height: 910px;
    background-image: url(../../assets/energy_bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 92px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
        font-size: 44px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 62px;
        margin-bottom: 43px;
    }
    .text{
        display: inline-block;
        width: 1196px;
        font-size: 26px;
        line-height: 38px;
        text-align: left;
        text-indent:2em;
    }
  }
  .banner3{
      padding-top: 85px;
      padding-bottom: 60px;
      text-align: center;
      .title{
        font-size: 48px;  
        font-weight: bold;
        color: #333333;
        line-height: 67px;
      }
      .map{
        height: 1004px;
        width: 100%;
      }
  }
  .banner4{
    height: 632px;
    background: #F7F7F7;
    box-sizing: border-box;
    .carousel-item{
        padding-top: 90px;
        padding-left: 350px;
        display: flex;
        justify-content: space-between;
        .title{
            font-size: 36px;
            font-weight: bold;
            color: #333333;
            padding-bottom: 16px;
            border-bottom: 2px solid #FE6101;
        }
        .name{
            font-size: 65px;
            font-weight: bold;
            color: #333333;
            margin-top: 70px;
        }
        .text{
            font-size: 20px;
            color: #333333;
            line-height: 28px;
            width: 750px;
            margin-top: 45px;
        }
    }
    .mirror{
        width: 750px;
        height: 355px;
    }
  }
  .banner5{
    height: 730px;
    background-image: url(../../assets/energy_banner4.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 55px 360px;
    text-align: center;
    .title{
        font-size: 48px;  
        font-weight: bold;
        color: #ffffff;
        line-height: 67px;
        margin-bottom: 45px;
    }
    .carousel-item{
        width: 690px;
        height: 415px;
        font-size: 0;
        .left{
            display: inline-block;
            width: 345px;
            box-shadow: 0px 10px 2px 0px;
        }
        .right{
            float: right;
            width: 345px;
            height: 100%;
            background: #FFFFFF;
            text-align: left;
            padding: 16px 20px;
            .name{
                font-size: 22px;
                line-height: 30px;
                color: #333333;
                margin-bottom: 12px;
            }
            .text{
                color: #333333;
                line-height: 22px;
                font-size: 14px;
            }
        }
    }
  }
  .banner6{
    height: 1031px;
    width: 100%;
    padding: 70px 340px;
    background: #FFFFFF;
    text-align: center;
    .title{
        font-size: 48px;  
        font-weight: bold;
        color: #333333;
        line-height: 67px;
        margin-bottom: 45px;
    }
    .img{
        width: 100%;
    }
  }
  .banner7{
    height: 800px;
    width: 100%;
  }
}
</style>

<style lang="less">
.banner4{
    .el-carousel,.el-carousel__container{
        height: 100%;
    }
    .el-carousel__button {
        width: 51px;
        height: 6px;
        background: #FE6101;
        border-radius: 3px;
    }
}
.banner5{
    .el-carousel{
        height: 415px;
    }
}
</style>