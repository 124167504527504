<template>
  <div class="ipo-service" id="ipo-service">
    <el-carousel :interval="3000" arrow="never">
      <el-carousel-item>
        <img class="banner" src="https://cdn.sdh365.com/ow/ss_20210914134949.jpg" alt="" />
        <div class="carousel-btn">
          <button class="btn1" @click="showPopMessage">获取方案</button>
          <button class="btn2" @click="showChat">立即咨询</button>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <img class="banner" src="https://cdn.sdh365.com/ow/ss_20210914134955.jpg" alt="" />
        <div class="carousel-btn">
          <button class="btn1" @click="showPopMessage">获取方案</button>
          <button class="btn2" @click="showChat">立即咨询</button>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="steps">
      <h3 class="steptitle">3秒了解赴美上市流程及步骤</h3>
      <div class="stepsubtitle">师董会全程深度参与，提供企业定制化上市路径</div>
      <div class="step">
        <!-- <div class="step1">01 前期准备</div> -->
        <div class="listed1">
          1.确定上市主体<br />
          2.确定上市使用财务数据年度<br />
          3.确定企业重组方案<br />
          4.聘任推荐人，申报会计师及 其他中介机构<br />
          5.各中介机构尽职调查
        </div>
        <!-- <div class="step2">02 启动上市</div> -->
        <div class="listed2">
          1.完成招标书初稿 <br />
          2.完成拟上市企业过往两个会计 年度美国港会计准则和美国证监
          会上市规则编制的款及报告<br />
          3.对拟上市企业的债项情况进行 确认
        </div>
        <!-- <div class="step3">03 提交申请</div> -->
        <div class="listed3">
          1.提交Form F-1上市申请表格 <br />
          2.提交审计报告<br />
          3.提交法律意见书
        </div>
        <!-- <div class="step4">04 成功上市</div> -->
        <div class="listed4">
          1.披露须予公布的交易 <br />
          2.业绩公告<br />
          3.股东大会
        </div>
      </div>
      <h3 class="steptitles">选择师董会，进入海外上市快速通道</h3>
      <div class="stepsubtitle">师董会上市服务团队六大优势</div>
      <div class="channels">
        <div class="channel">
          <div class="channellist1">
            <div class="list1">
              <div class="img">
                <img src="../../assets/suffer.png" alt="" />
              </div>
              <div class="ner">经验丰富</div>
            </div>
            <div class="introduction">
              团队平均从业年限超10年上市项目经验丰富
            </div>
          </div>
          <div class="channellist1">
            <div class="list1">
              <div class="img">
                <img src="../../assets/professional.png" alt="" />
              </div>
              <div class="ner">专业强化</div>
            </div>
            <div class="introduction">
              一体化服务涵盖境内外法务财务、审计、公关
            </div>
          </div>
          <div class="channellist1">
            <div class="list1">
              <div class="img">
                <img src="../../assets/successrate.png" alt="" />
              </div>
              <div class="ner">成功率高</div>
            </div>
            <div class="introduction">
              熟悉上市精准路径提前规避可能存在的风险
            </div>
          </div>
        </div>
        <div class="channel">
          <div class="channellist1">
            <div class="list1">
              <div class="img">
                <img src="../../assets/lowercost.png" alt="" />
              </div>
              <div class="ner">降低成本</div>
            </div>
            <div class="introduction">
              深度参与IPO全程提供一站式高效解决方案
            </div>
          </div>
          <div class="channellist1">
            <div class="list1">
              <div class="img">
                <img src="../../assets/tax.png" alt="" />
              </div>
              <div class="ner">筹划税务</div>
            </div>
            <div class="introduction">提前筹划税务安排有效降低税务风险</div>
          </div>
          <div class="channellist1">
            <div class="list1">
              <div class="img">
                <img src="../../assets/coordination.png" alt="" />
              </div>
              <div class="ner">资源协调</div>
            </div>
            <div class="introduction">
              背靠强大资源体系解决上市期间资金资源问题
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <button class="type plan" @click="showPopMessage">
          获取专家小组服务方案
        </button>
        <button class="type consult" @click="showChat">在线咨询</button>
      </div>
    </div>
    <div class="prepare">
      <h3 class="preparetitle">跨境上市将成为热潮，您准备好了吗？</h3>
      <div class="preparesubtitle">美国上市为中国企业带来的优势</div>
      <div class="setout">
        <div class="setoutleft">
          <span class="li">· 能够合法快速反复融资</span><br />
          <span class="li">· 提高知名度扩大市场</span><br />
          <span class="li">· 激励高管聘请专业人士</span><br />
          <span class="li">· 税务征收管理更加灵活</span><br />
          <span class="li">· 用DR方式在各国上市</span>
        </div>
        <div class="setoutimg">
          <img src="../../assets/prepare.png" alt="" />
        </div>
        <div class="setoutright">
          <span class="li">· 成为合资企业享受优惠</span><br />
          <span class="li">· 兼并相关企业扩大规模</span><br />
          <span class="li">· 增发股价赚取利润</span><br />
          <span class="li">· 可采用IPO方式筹资</span><br />
          <span class="li">· 成熟资本市场融资灵活</span>
        </div>
      </div>
      <div class="btns">
        <button class="type plan" @click="showPopMessage">
          获取专家小组服务方案
        </button>
        <button class="type consult" @click="showChat">在线咨询</button>
      </div>
      <h3 class="preparetitles">上市服务团队核心成员</h3>
      <div class="core">
        <div class="second">
          <div class="avatar"><img src="../../assets/second.png" alt="" /></div>
          <div class="name">左海威</div>
          <div class="post">师董会副董事长<br />师董会纳斯达克上市副总指挥</div>
        </div>
        <div class="first">
          <div class="avatar"><img src="../../assets/first.png" alt="" /></div>
          <div class="name">胡海平</div>
          <div class="post">
            师董会专家小组组长<br />师董会上市总指挥<br />师董会董事长<br />中国企业年度经济人物(2020)<br />荣获光荣浙商<br />中国十大经济影响力人物<br />十大新能源人物<br />中国十大杰出CEO
          </div>
        </div>
        <div class="third">
          <div class="avatar"><img src="../../assets/third.png" alt="" /></div>
          <div class="name">刘超</div>
          <div class="post">
            师董会首席财务官<br />师董会纳斯达克上市财务负责人
          </div>
        </div>
      </div>
      <div class="consultant">
        <h3 class="preparetitled">上市服务团队合作顾问成员</h3>
        <div class="wall">
          <img src="https://cdn.sdh365.com/ow/photowall.png" alt="" />
        </div>
      </div>
    </div>
    <div class="banner4">
      <Message btntext="免费咨询"></Message>
    </div>
  </div>
</template>

<script>
import Message from '../../components/message'
export default {
  name: 'Ipo-service',
  components: {
    Message,
  },
  methods: {
    showChat() {
      this.$store.commit('showChat')
    },
    showPopMessage() {
      this.$store.commit('showPopMessage')
    },
  },
}
</script>

<style lang="less" scoped>
.ipo-service {
  .el-carousel {
    height: 620px;
  }
  .el-carousel__container {
    position: relative;
    .el-carousel__item {
      height: 620px;
    }
  }
  .carousel-btn {
    position: absolute;
    top: 470px;
    left: 39%;
    .btn1,
    .btn2 {
      width: 210px;
      height: 50px;
      border: 1px solid #ffffff;
      border-radius: 31px;
      cursor: pointer;
      font-weight: 500;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    .btn1 {
      background-color: #ffffff;
      color: #131b40;
      font-size: 18px;
    }
    .btn1:hover {
      background-color: #e6e6e6;
    }
    .btn2:hover {
      background: rgba(255, 255, 255, 0.2);
    }
    .btn2 {
      background-color: transparent;
      color: rgba(255, 255, 255, 0.8);
      margin-left: 20px;
      font-size: 16px;
    }
  }
  .banner {
    height: 100%;
    width: 100%;
  }
  .steps {
    background-color: #f7f7f7;
    padding-top: 64px;
    padding-bottom: 50px;
    .steptitle,
    .steptitles {
      width: 100%;
      text-align: center;
      color: #333333;
      font-size: 36px;
    }
    .stepsubtitle {
      width: 100%;
      text-align: center;
      margin-top: 13px;
      font-size: 20px;
      color: #333333;
    }
    .step {
      height: 270px;
      width: 1200px;
      background: url(../../assets/step.png) no-repeat;
      background-size: cover;
      background-position: center;
      margin: 90px auto 15px;
      position: relative;
      .step1,
      .step2,
      .step3,
      .step4 {
        font-size: 28px;
        font-family: 'HuXiaoBo-NanShen', 'HuXiaoBo-NanShen-Regular';
        color: #ffffff;
        position: absolute;
      }
      .listed1,
      .listed2,
      .listed3,
      .listed4 {
        width: 252px;
        height: 150px;
        color: #333333;
        position: absolute;
        font-size: 18px;
      }
      .step1 {
        top: 25px;
        left: 102px;
      }
      .listed1 {
        top: 164px;
        left: 60px;
      }
      .step2 {
        bottom: 25px;
        left: 381px;
      }
      .listed2 {
        top: -10px;
        left: 339px;
      }
      .step3 {
        right: 383px;
        top: 25px;
      }
      .listed3 {
        top: 164px;
        left: 618px;
      }
      .step4 {
        right: 101px;
        top: 215px;
      }
      .listed4 {
        top: 12px;
        right: 50px;
      }
    }
    .steptitles {
      margin-top: 77px;
    }
    .channels {
      margin: 50px auto 0;
      width: 1200px;
      .channel {
        display: flex;
        justify-content: space-between;
        padding-bottom: 31px;
        .channellist1 {
          width: 400px;
          .list1 {
            display: flex;
            align-items: center;
            .img {
              width: 64px;
              height: 64px;
              img {
                width: 100%;
              }
            }
            .ner {
              color: #333333;
              font-size: 26px;
              font-weight: 500;
            }
          }
          .introduction {
            font-size: 18px;
            color: #666666;
            margin-left: 8px;
            margin-top: 14px;
          }
        }
      }
      .channel:last-child {
        border-top: 1px solid #dddddd;
        padding-top: 36px;
      }
    }
  }
  @-webkit-keyframes bounce-up {
    25% {
      -webkit-transform: translateY(10px);
    }
    50%,
    100% {
      -webkit-transform: translateY(0);
    }
    75% {
      -webkit-transform: translateY(-10px);
    }
  }
  .prepare {
    .preparetitle,
    .preparetitles,
    .preparetitled {
      text-align: center;
      margin-top: 60px;
      color: #333333;
      font-size: 36px;
    }
    .preparetitles {
      margin-top: 100px;
    }
    .preparetitled {
      margin-top: 0;
      margin-bottom: 55px;
    }
    .preparesubtitle {
      font-size: 20px;
      color: #666666;
      font-weight: 500;
      text-align: center;
      margin-top: 7px;
    }
    .setout {
      display: flex;
      justify-content: center;
      margin-top: 42px;
      align-items: center;
      .setoutimg {
        width: 518px;
        height: 406px;
        img {
          width: 100%;
          animation: bounce-up 3s linear infinite;
        }
      }
      .setoutleft {
        margin-right: 10px;
      }
      .setoutright {
        margin-left: 10px;
      }
      .setoutleft .li,
      .setoutright .li {
        color: #666666;
        font-size: 30px;
        height: 60px;
        line-height: 60px;
      }
    }
    .core {
      background: url(../../assets/core.png) no-repeat;
      background-size: cover;
      width: 1200px;
      height: 708px;
      margin: 20px auto 7px;
      display: flex;
      justify-content: space-around;
      padding: 0 210px;
      .second,
      .third,
      .first {
        width: 260px;
        text-align: center;
        margin: 0 auto;
        .avatar {
          width: 146px;
          height: 146px;
          border-radius: 50%;
          margin: 0 auto;
          img {
            width: 100%;
          }
        }
        .name {
          font-size: 30px;
          font-weight: bold;
          margin-top: 10px;
        }
        .post {
          font-size: 16px;
          color: #333333;
          margin-top: 10px;
          line-height: 22px;
        }
      }
      .first {
        margin-top: 71px;
        .avatar {
          width: 210px;
          height: 210px;
          border-radius: 50%;
          margin: 0 auto;
          img {
            width: 100%;
          }
        }
      }
      .second,
      .third {
        margin-top: 103px;
      }
    }
    .consultant {
      .wall {
        width: 100%;
        height: 1361px;
        img {
          width: 100%;
        }
      }
    }
  }
  .banner4 {
    height: 500px;
    background-image: url(../../assets/banner_home3.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-left: 360px;
    padding-top: 144px;
    margin-top: -12px;
  }
  .btns {
    margin-top: 75px;
    text-align: center;
    .type {
      border-radius: 4px;
      border: none;
      outline: none;
      font-size: 18px;
      width: 220px;
      height: 46px;
    }
    .plan {
      color: #ffffff;
      background-color: #ff7b00;
      margin-right: 20px;
      cursor: pointer;
    }
    .plan:hover {
      background-color: #e76f00;
    }
    .consult {
      border: 1px solid #ff7b00;
      background-color: #ffffff;
      color: #ff7b00;
      box-shadow: 0px 4px 4px 0px rgba(255, 123, 0, 0.2);
      cursor: pointer;
    }
    .consult:hover {
      background: rgba(255, 123, 0, 0.1);
    }
  }
}
</style>
<style>
.el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
}
</style>