<template>
  <div class="main">
    <div class="article">
      <div class="header clear-fix">
        <h3>{{ article.title }}</h3>
        <span class="time"
          >时间：{{ article.releaseTime }} 来源人：{{ article.source }}</span
        >
      </div>
      <div>
        <div class="contents" v-html="article.content"></div>
      </div>
      <div class="beforeafter">
        <div
          class="before"
          v-if="JSON.stringify(this.article.before) != '{}'"
          @click="goarticle(article.before.articleId)"
        >
          上一页：{{ beforetitle }}
        </div>
        <div
          class="after"
          v-if="JSON.stringify(this.article.after) != '{}'"
          @click="goarticle(article.after.articleId)"
        >
          下一页：{{ aftertitle }}
        </div>
      </div>
    </div>
    <div class="right">
      <div class="recent">
        <h4>近期活动推荐</h4>
        <p
          class="text"
          v-for="(item, index) in activityList"
          :key="index"
          @click="goarticle(item.articleId)"
        >
          {{ index + 1 }}. {{ item.title }}
        </p>
      </div>
      <div class="recommend">
        <h4>相关文章推荐</h4>
        <div
          class="recommendcontent"
          v-for="(item, index) in recommendList"
          :key="index"
          @click="goarticle(item.articleId)"
        >
          <div class="cover"><img :src="item.cover" alt="" /></div>
          <div class="articlecontent">
            <div class="articletitle">{{ item.title }}</div>
            <div class="subtitle">{{ item.intro }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "News-Detail",
  data() {
    return {
      article: {
        title: "",
        releaseTime: "",
        source: "",
        content: "",
      },
      activityList: [],
      recommendList: [],
      beforetitle: "",
      aftertitle: "",
    };
  },
  created() {
    this.getNews();
    console.log(this.$route.query.id);
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  methods: {
    goarticle(articleId) {
      this.$router.push("/news-detail?id=" + articleId);
      this.getDetail();
    },
    getDetail() {
      request
        .post("/in/website/articleDetails", { articleId: this.$route.query.id })
        .then((res) => {
          console.log(res.data);
          this.article = res.data;
          this.beforetitle = this.article.before.title;
          this.aftertitle = this.article.after.title;
        });
    },
    getNews() {
      request
        .post("/in/website/recommend/articleList", { type: 1, pageSize: 3 })
        .then((res) => {
          this.recommendList = res.data;
          console.log(this.recommendList);
        });
      request
        .post("/in/website/recommend/articleList", { type: 2, pageSize: 10 })
        .then((res) => {
          this.activityList = res.data;
          console.log(this.activityList);
        });
    },
  },
  watch: {
    $route(to) {
      console.log(to.query.id);
      this.getDetail();
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
}
.article {
  display: inline-block;
  width: 770px;
  min-height: 1098px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 50px;
  // margin-left: 360px;
  margin-bottom: 130px;
  padding-top: 30px;
  padding-left: 45px;
  padding-right: 40px;
  margin-right: 30px;
  .header {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 20px;
    margin-bottom: 37px;
    h3 {
      line-height: 31px;
      font-size: 22px;
      font-weight: 700;
      color: #333333;
      text-align: center;
    }
    .time {
      float: right;
      margin-right: 130px;
      margin-top: 15px;
      font-size: 14px;
      color: #666666;
    }
  }
  .contents {
    min-height: 750px;
    word-wrap: break-word;
    // background-color: #ffffff;
  }
  .contents/deep/img {
      max-width: 680px;
      height: 100%;
      margin: 0 auto;
      display: flex;
  }
  .contents/deep/figure {
      max-width: 680px;
      height: 100%;
      margin: 0 auto;
      display: flex;
  }
  .beforeafter {
    padding-top: 30px;
    margin-bottom: 53px;
    border-top: 1px solid #eeeeee;
    //   background-color: #ffffff;
    margin-top: 50px;
    .before,
    .after {
      margin-bottom: 10px;
      cursor: pointer;
    }
    .before:hover,
    .after:hover {
      color: #ff7b00;
    }
  }
}
.right {
  // float: right;
  // margin-right: 358px;
}
.recent {
  width: 400px;
  height: 480px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 50px;
  padding-top: 30px;
  padding-left: 23px;
  h4 {
    width: 156px;
    height: 36px;
    font-size: 26px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 15px;
    line-height: 22px;
    cursor: pointer;
    width: 380px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  p:hover {
    color: #ff7b00;
  }
}
.recommend {
  width: 400px;
  height: 480px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 20px;
  padding-top: 30px;
  padding-left: 23px;
  h4 {
    width: 156px;
    height: 36px;
    font-size: 26px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 20px;
  }
  .recommendcontent {
    height: 89px;
    margin-bottom: 20px;
    display: flex;
    .cover {
      width: 138px;
      height: 89px;
      margin-right: 8px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
    .articletitle {
      width: 234px;
      height: 50px;
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
      color: #333333;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .subtitle {
      width: 234px;
      height: 20px;
      font-size: 14px;
      color: #666666;
      margin-top: 15px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
  }
  .recommendcontent:hover {
    .articletitle {
      color: #ff7b00;
    }
  }
}
</style>