<template>
  <div class="resource">
    <el-carousel :interval="3000" arrow="never" class="carousels">
      <el-carousel-item>
        <img class="banner" src="https://cdn.sdh365.com/ow/ss_20210914134949.jpg" alt="" />
        <div class="carousel-btn">
          <button class="btn1" @click="showChat">立即咨询</button>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <img class="banner" src="https://cdn.sdh365.com/ow/ss_20210914134955.jpg" alt="" />
        <div class="carousel-btn">
          <button class="btn1" @click="showChat">立即咨询</button>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="main">
      <div class="part" ref="part1">
        <h4>商业模式<span>And</span>企业亮点</h4>
        <div class="flex">
          <div class="item">
            <div class="hover">
              <p>
                以线上用户、线下会员为
                主要服务对象，以知识付费、商务活动、企业深度咨询、投融资收益、资源推介、直播网红综合体、区域产业赋能等为主要盈利模式
              </p>
              <span @click="showChat">立即咨询</span>
            </div>
          </div>
          <div class="item">
            <div class="hover">
              <p>
                以“互相学习、互相帮助、
                互相交易、互相投资“为服务理念，倡导“共享智慧、共享资源、共享办公共享服务“的共享经济发展方向
              </p>
              <span @click="showChat">立即咨询</span>
            </div>
          </div>
          <div class="item">
            <div class="hover">
              <p>
                共享经济市场前景巨大，知识付费细分领域龙头，创始人胡海平成功背景庞大全球专家库
              </p>
              <span @click="showChat">立即咨询</span>
            </div>
          </div>
          <div class="item">
            <div class="hover">
              <p>
                企业服务生态链，智联网发展历史机遇，全球数字生态科创平台，新产品/服务发展潜力，盈利稳定持续，强劲财务税务增长预期
              </p>
              <span @click="showChat">立即咨询</span>
            </div>
          </div>
        </div>
      </div>
      <div class="part" ref="part2">
        <h4 class="h4">优秀导师专家推荐</h4>
        <p class="p">
          他们是站在中国产业发展前沿的一线企业家，是来自两院院士、高等学府及各领域的顶尖科学家，是投资精准的投资家
        </p>
        <div class="center center1">
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="k in Math.ceil(mentorLists.length / 6)" :key="k">
              <div class="flex_carousel">
                <div class="item" v-for="item in mentorLists.slice((k - 1) * 6, k * 6)" :key="item.userId">
                  <div class="top">
                    <div class="left">
                      <h5>{{ item.nickname }}</h5>
                      <p>
                        师董会（股票代码：SDH）董事长师董会（股票代码：SDH）董事长
                      </p>
                      <div class="tag" v-if="item.classifyName.length > 0">
                        <span v-for="(i, index) in item.classifyName" :key="index">{{ i }}</span>
                      </div>
                    </div>
                    <div class="right">
                      <img :src="item.avatar" alt="" />
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="consultNumber">
                      已有{{ item.consultNumber }}人咨询
                    </div>
                    <div class="btns" @click="tutorDetail(item.userId)">
                      去咨询
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="part" ref="statistics">
        <div class="count">
          <div class="contain">
            <div class="center_cup">
              <h5>服务规模</h5>
              <div class="count_cup" id="statistics">
                <div class="count_item">
                  <p>
                    协助<countTo ref="count1" :startVal="0" :endVal="20" :duration="1500" suffix="家"></countTo>
                  </p>
                  <p>企业获得私募融资</p>
                </div>
                <div class="count_item">
                  <p>
                    超过<countTo ref="count2" :startVal="0" :endVal="60" :duration="1500" suffix="亿"></countTo>
                  </p>
                  <p>人民币累计融资金额</p>
                </div>
                <div class="count_item">
                  <p>
                    协助<countTo ref="count3" :startVal="0" :endVal="51" :duration="1500" suffix="家"></countTo>
                  </p>
                  <p>企业在境内外首发上市</p>
                </div>
                <div class="count_item">
                  <p>
                    目前<countTo ref="count4" :startVal="0" :endVal="15" :duration="1500" suffix="家"></countTo>
                  </p>
                  <p>企业市值超过百亿</p>
                </div>
                <div class="count_item">
                  <p>
                    目前<countTo ref="count5" :startVal="0" :endVal="2" :duration="1500" suffix="家"></countTo>
                  </p>
                  <p>企业市值超过千亿</p>
                </div>
                <div class="count_item">
                  <p>
                    超<countTo ref="count6" :startVal="0" :endVal="400" :duration="1500" suffix="亿"></countTo>
                  </p>
                  <p>人民币累计IPO融资金额</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="part">
        <div class="cup">
          <h4>商业资源</h4>
          <p>
            全行业专业服务+多渠道精准曝光+好资源高效对接+转型升级需求全面覆盖
          </p>
          <div class="flexbox">
            <div class="item_cup">
              <div class="img_cup">
                <img class="imgshow" src="../../assets/di01.png" alt="" />
                <img class="changeshow" src="../../assets/di01_h.png" alt="" />
              </div>
              <h5>关注人脉变现、个人成长</h5>
              <ul>
                <li>师董智家合伙人</li>
                <li>产品展销</li>
                <li>路演融资</li>
                <li>培训游学</li>
                <li>企业微咨询</li>
                <li>大咖晚宴</li>
              </ul>
              <span @click="showChat">立即咨询</span>
            </div>
            <div class="item_cup">
              <div class="img_cup">
                <img class="imgshow" src="../../assets/di02.png" alt="" />
                <img class="changeshow" src="../../assets/di02_h.png" alt="" />
              </div>
              <h5>关注企业全方位成长</h5>
              <ul>
                <li>美国上市服务师</li>
                <li>董事业部合伙人</li>
                <li>师董咨询</li>
                <li>资本赋能</li>
                <li>师董投资</li>
                <li>师董基金</li>
              </ul>
              <span @click="showChat">立即咨询</span>
            </div>
            <div class="item_cup">
              <div class="img_cup">
                <img class="imgshow" src="../../assets/di03.png" alt="" />
                <img class="changeshow" src="../../assets/di03_h.png" alt="" />
              </div>
              <h5>关注地方政府招商引资<br />培育上市企业</h5>
              <ul>
                <li>区域总部</li>
                <li>产业中国</li>
              </ul>
              <span @click="showChat">立即咨询</span>
            </div>
          </div>
        </div>
        <div class="centcup">
          <div class="center center2">
            <div class="title">
              <h6>师董资源</h6>
              <p>
                强大的师董会平台企业资源库，细分行业、地区等多个关键纬度，快速帮助企业<br />
                打通链接资源、人脉的线上渠道。加入师董会，一键触达你想要的资源
              </p>
            </div>

            <el-carousel :interval="5000" arrow="always">
              <el-carousel-item v-for="k in Math.ceil(resourceLists.length / 6)" :key="k">
                <div class="flex_carousel">
                  <div class="item2" v-for="item in resourceLists.slice((k - 1) * 6, k * 6)" :key="item.id">
                    <div class="card">
                      <div class="txt">
                        <h6>{{ item.companyName }}</h6>
                        <p>{{ item.rank }}</p>
                      </div>
                      <div class="img_cup">
                        <img :src="item.avatar" alt="" />
                      </div>
                    </div>
                    <div class="infor">
                      <p>
                        <span>·行业类别</span>
                        <span>{{ item.classification }}</span>
                      </p>
                      <p>
                        <span>·可提供服务</span>
                        <span>{{ item.canOffer }}</span>
                      </p>
                      <div class="btn">
                        <span @click="msgbtn">在线留言</span>
                        <span @click="showChat">立即咨询</span>
                      </div>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="metacup">
          <div class="maincenter">
            <h3>媒体资源</h3>
            <p>
              多栏目、多角度、多形式，打造渠道覆盖面高、传播流量大的商业实战教科书和资源合作交流平台
            </p>
            <div class="tab">
              <div class="title_flex">
                <div class="tab_title" v-for="(i, index) in tabList" :key="index + i" @mouseenter="mouseenter(index)" @mouseleave="mouseleave(index)" :class="{ tab_active: numshow == index }">
                  {{ i.tabname }}
                </div>
              </div>
              <div class="box2">
                <div class="flex_box">
                  <div v-for="(c, index) of 5" :key="index" class="photo_box" :class="{ photo_active: numshow == index }" :style="{
                      'background-image':
                        'url(' + require(`../../assets/tab${index}.png`) + ')',
                    }"></div>
                </div>
              </div>
              <!-- <div class="box">
                <div v-for="(c, index) of 5" :key="index" class="photo-box">
                  <div
                    class="photo"
                    :class="c == 3 ? 'photo-active' : ''"
                    :style="{
                      'background-image':
                        'url(' +
                        require(`../../assets/tab${
                          picShowList[c - 1].num
                        }.png`) +
                        ')'
                    }" @click="jump(c)"
                  >
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="banner10">
          <div class="bannerCenter">
            <h3>深度合作伙伴</h3>
            <h5>
              多行业优秀的企业选择与师董会同行，携手共建智联网企业服务生态链
            </h5>
            <div class="tab ml360" :class="banner10_tab == 'chanye' ? 'tab-active' : ''" @click="banner10_tab = 'chanye'">
              产业资源
            </div>
            <div class="tab" :class="banner10_tab == 'touhang' ? 'tab-active' : ''" @click="banner10_tab = 'touhang'">
              投行阵容
            </div>
            <div v-show="banner10_tab == 'chanye'" class="logo-box ml360">
              <img v-for="index of 18" :key="index" :src="`https://cdn.sdh365.com/ow/chanye${index}.png`" class="logo" />
            </div>
            <div v-show="banner10_tab == 'touhang'" class="logo-box ml360">
              <img v-for="index of 18" :key="index" :src="`https://cdn.sdh365.com/ow/touhang${index}.png`" class="logo" />
            </div>
            <el-button class="btn2" type="primary" @click="showChat">立即咨询</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
import countTo from 'vue-count-to'

export default {
  name: 'resource',
  components: { countTo },
  data() {
    return {
      mentorLists: [],
      resourceLists: [],
      numshow: 0,
      pageTo: 1,
      pageSize: 29,
      mentorListTotal: '',
      resourceListTotal: '',
      classifyName: [],
      banner10_tab: 'chanye',
      picNum: 5,
      picList: [
        {
          color: 'pink',
          num: 2,
        },
        {
          color: 'orange',
          num: 1,
        },
        {
          color: 'yellow',
          num: 0,
        },
        {
          color: 'green',
          num: 3,
        },
        {
          color: 'blue',
          num: 4,
        },
      ],
      tabList: [
        {
          num: 0,
          tabname: '海平会客室',
        },
        {
          num: 1,
          tabname: '寻找独角兽',
        },
        {
          num: 2,
          tabname: '海平海聊',
        },
        {
          num: 3,
          tabname: '盘道访谈',
        },
        {
          num: 4,
          tabname: '可触达媒体',
        },
      ],
      picStart: 0,
      picEnd: 5,
      picShowList: [],
      timer: null,
    }
  },
  mounted() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
    this.timer = setInterval(() => {
      this.numshow += 1
      if (this.numshow > 4) {
        this.numshow = 0
      }
    }, 5000)
  },
  methods: {
    tutorDetail(userId) {
      this.$router.push('/tutor-detail?userId=' + userId)
    },
    mouseenter(index) {
      this.numshow = index
      clearInterval(this.timer)
      this.timer = null
    },
    mouseleave() {
      this.timer = setInterval(() => {
        this.numshow += 1
        if (this.numshow > 4) {
          this.numshow = 0
        }
      }, 5000)
    },
    msgbtn() {
      this.$store.commit('showPopMessage')
    },
    toogle(n) {
      if (this.picStart + n < 0) {
        this.picShowList = []
        this.picList.slice(this.picStart + n).map(n => {
          this.picShowList.push(n)
        })
        this.picList.slice(0, 5 + (this.picStart + n)).map(n => {
          this.picShowList.push(n)
        })
      } else if (this.picEnd + n > this.picList.length) {
        this.picShowList = []
        this.picList.slice(this.picEnd + n - this.picList.length - 5).map(n => {
          this.picShowList.push(n)
        })
        this.picList.slice(0, this.picEnd + n - this.picList.length).map(n => {
          this.picShowList.push(n)
        })
      } else {
        this.picShowList = this.picList.slice(this.picStart + n, this.picEnd + n)
      }
      this.picStart = this.picStart + n
      this.picEnd = this.picEnd + n
    },
    jump(n) {
      this.toogle(n - 3)
    },
    mentorList() {
      request.post('/in/website/recommend/mentorList', { pageTo: 1, pageSize: 29 }).then(res => {
        // console.log(res);
        this.mentorLists = res.data.items
        this.mentorListTotal = res.data.totalPageCount
        this.mentorLists.forEach(item => {
          item.classifyName = item.classifyName.split(',')
          // console.log(item)
        })
      })
    },
    resourceList() {
      request.post('/in/website/resourceList', { pageTo: 1, pageSize: 29 }).then(res => {
        // console.log(res)
        this.resourceLists = res.data.items
        this.resourceListTotal = res.data.totalPageCount
      })
    },
    handleScroll() {
      let docSrollTop = document.documentElement.scrollTop //文档卷动值
      let clientH = window.innerHeight //视窗大小
      let sectionTop = this.$refs.statistics.offsetTop //动态文字模块距离文档头部的距离
      let sectionH = this.$refs.statistics.offsetHeight
      let p1 = this.$refs.part1.offsetHeight
      let p2 = this.$refs.part2.offsetHeight
      if (docSrollTop + clientH - sectionTop >= 0 && docSrollTop - sectionTop - sectionH + (p1 + p2) <= 0) {
        this.$refs.count1.start()
        this.$refs.count2.start()
        this.$refs.count3.start()
        this.$refs.count4.start()
        this.$refs.count5.start()
        this.$refs.count6.start()
      } else {
        this.autoplay = false
      }
    },
    showChat() {
      this.$store.commit('showChat')
    },
  },
  created() {
    this.mentorList()
    this.resourceList()
    this.picShowList = this.picList.slice(this.picStart, this.picEnd)
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>
<style lang="less">
.resource {
  .el-icon-arrow-left:before {
    content: '<<';
  }
  .el-icon-arrow-right:before {
    content: '>>';
  }
  .el-carousel__arrow,
  .el-carousel__arrow:hover {
    background: transparent;
  }
  .el-carousel__arrow:hover {
    color: #ff7b00;
  }
  .el-carousel__arrow {
    margin-top: -20px;
    color: #ccc;
    font-size: 25px;
    font-weight: normal;
    transform: scale(1, 2);
    -ms-transform: scale(1, 2);
    -webkit-transform: scale(1, 2);
    -moz-transform: scale(1, 2);
    -o-transform: scale(1, 2);
  }
}
.center1 {
  .el-carousel__container {
    width: 1200px;
    margin: 0 auto;
    height: 490px;
  }
}
.maincenter .tab {
  .title_flex {
    display: flex;
    justify-content: flex-start;
    width: 1200px;
    margin: 0 auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    .tab_title {
      width: 240px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      border-bottom: 2px solid transparent;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
    .tab_active {
      border-bottom: 2px solid #fff;
    }
  }
  .box {
    margin: 52px auto 0;
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .photo-box {
      position: absolute;
      .name {
        font-size: 26px;
        color: #ff7b00;
        margin-top: 24px;
        margin-bottom: 11px;
      }
      .info {
        display: inline-block;
        width: 420px;
        line-height: 22px;
        font-size: 16px;
        color: #333333;
        text-align: center;
        position: absolute;
        left: -25px;
      }
    }
    .photo-box:nth-child(1),
    .photo-box:nth-child(5) {
      .photo {
        width: 381px;
        height: 222px;
        cursor: pointer;
      }
    }
    .photo-box:nth-child(2),
    .photo-box:nth-child(4) {
      .photo {
        width: 523px;
        height: 304px;
        cursor: pointer;
      }
    }
    .photo-box:nth-child(1) {
      left: 0px;
      top: 87px;
    }
    .photo-box:nth-child(2) {
      left: 100px;
      top: 48px;
      z-index: 666;
    }
    .photo-box:nth-child(3) {
      z-index: 999;
      left: 50%;
      margin-left: -344px;
    }
    .photo-box:nth-child(4) {
      right: 100px;
      top: 48px;
      z-index: 666;
    }
    .photo-box:nth-child(5) {
      right: 0px;
      top: 87px;
    }
    .photo {
      width: 170px;
      height: 170px;
      cursor: pointer;
      background-image: no-repeat;
      background-size: cover;
    }
    .photo-active {
      width: 688px;
      height: 400px;
      margin-top: 0;
    }
    .pre,
    .next {
      width: 40px;
      height: 40px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 150px;
      cursor: pointer;
    }
    .pre {
      float: left;
      background-image: url(../../assets/pre.png);
      margin-right: 35px;
    }
    .next {
      float: right;
      background-image: url(../../assets/next.png);
    }
  }
  .box2 {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    top: 128px;
    .flex_box {
      // position: absolute;
      // left: 0;
      // display: flex;
      // justify-content: center;
      .photo_box {
        width: 523px;
        height: 304px;
        background-size: cover;
        position: relative;
        top: -30px;
        // -moz-transition: all 0.5s ease-in;
        //     -webkit-transition: all 0.5s ease-in;
        //     -o-transition: all 0.5s ease-in;
        //     -ms-transition: all 0.5s ease-in;
        //     transition: all 0.5s ease-in;
        // margin-right: -295px;
        // z-index: 666;
      }
      .photo_active {
        width: 688px;
        height: 400px;
        background-size: cover;
        position: absolute;
        top: -80px;
        left: 50%;
        margin-left: -344px;
        z-index: 888;
      }
    }
    .photo_box:nth-child(1):not(.photo_active) {
      position: absolute;
      left: 0px;
      // top: 20px;
      // z-index: 666;
    }
    .photo_box:nth-child(2):not(.photo_active) {
      position: absolute;
      // top: 20px;
      // left: 0px;
      left: 0px;

      // z-index: 666;
    }
    .photo_box:nth-child(3):not(.photo_active) {
      position: absolute;
      right: 0;
      margin-left: -261.5px;

      z-index: 777;
    }

    .photo_box:nth-child(4):not(.photo_active) {
      position: absolute;
      right: 0;
      // z-index: 777;
    }
    .photo_box:nth-child(5):not(.photo_active) {
      position: absolute;
      right: 0px;
      // z-index: 666;
    }
  }

  .btn2 {
    margin-top: 175px;
  }
}
.metacup {
  width: 100%;
  height: 860px;
  background: url(../../assets/dbg02.png) no-repeat;
  background-size: contain;
  background-position: center;
  .maincenter {
    width: 100%;
    height: 100%;
    padding: 68px 0 111px;
    box-sizing: border-box;
    h3 {
      font-size: 48px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
    }
    p {
      margin: 10px 0 78px;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
    }
  }
}
.centcup {
  width: 100%;
  background: #fff;
  .center2 {
    padding: 80px 0 100px;
    box-sizing: border-box;
    .title {
      h6 {
        font-size: 36px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        margin: 0;
      }
      p {
        margin: 10px 0 25px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        text-align: center;
        color: #333333;
      }
    }
    .el-carousel__container {
      width: 1200px;
      margin: 0 auto;
      // background: blue;
      height: 640px;
    }
  }
}

.center {
  .el-carousel__indicators {
    display: none;
  }
  .el-carousel__indicators--horizontal {
    display: none;
  }
  .el-carousel__container {
    width: 1200px;
    margin: 0 auto;
    // background: blue;
    // height: 490px;
  }
  .el-carousel--horizontal {
    width: 1500px;
    margin: 0 auto;
    // background: pink;
  }
  .el-carousel__arrow--left {
    left: -145px;
  }
  .el-carousel__arrow--right {
    right: 30px;
  }
  .el-carousel__item,
  .el-carousel__mask {
    left: -95px;
  }
  .el-carousel__item {
    width: 1300px;
    margin: 0 auto;
    // background: red;
    padding: 10px;
    box-sizing: border-box;
  }
}

.el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
}
</style>
<style lang="less" scoped>
.resource {
  background: #f7f7f7;
  .carousels {
    height: 620px;
  }
  .el-carousel__container {
    position: relative;
    .el-carousel__item {
      height: 620px;
      .carousel-btn {
        position: absolute;
        top: 455px;
        left: 42%;
        .btn1 {
          width: 330px;
          height: 55px;
          border: 1px solid #ffffff;
          border-radius: 31px;
          cursor: pointer;
          font-weight: 500;
          background-color: #ffffff;
          color: #131b40;
          font-size: 18px;
          box-shadow: 0px 10px 6px 0px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
  .carousel-btn {
    position: absolute;
    top: 470px;
    left: 39%;
    .btn1,
    .btn2 {
      width: 210px;
      height: 50px;
      border: 1px solid #ffffff;
      border-radius: 31px;
      cursor: pointer;
      font-weight: 500;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    .btn1 {
      background-color: #ffffff;
      color: #131b40;
      font-size: 18px;
    }
    .btn1:hover {
      background-color: #e6e6e6;
    }
    .btn2:hover {
      background: rgba(255, 255, 255, 0.2);
    }
    .btn2 {
      background-color: transparent;
      color: rgba(255, 255, 255, 0.8);
      margin-left: 20px;
      font-size: 16px;
    }
  }
  .banner10 {
    height: 860px;
    background-image: url(../../assets/banner_home4.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 35px;
    .bannerCenter {
      width: 1200px;
      margin: 0 auto;
    }
    h3 {
      font-size: 36px;
      color: #333333;
      text-align: center;
    }
    h5 {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin-top: 17px;
    }
    .tab {
      display: inline-block;
      line-height: 74px;
      width: 600px;
      text-align: center;
      font-size: 24px;
      color: #333333;
      margin-top: 35px;
      border-bottom: 1px solid #dddddd;
      cursor: pointer;
    }
    .tab-active {
      color: #ff7b00;
      border-bottom: 1px solid #ff7b00;
    }
    .logo-box {
      width: 1218px;
      height: 445px;
      margin-top: 37px;
      display: flex;
      flex-wrap: wrap;
    }
    .logo {
      width: 189px;
      height: 135px;
      margin: 0 14px 20px 0px;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
      filter: gray;
    }
    .logo:hover {
      -webkit-filter: none;
      -moz-filter: none;
      -ms-filter: none;
      -o-filter: none;
      filter: none;
      filter: none;
    }
    .btn2 {
      margin: 48px auto 0;
      width: 218px;
      height: 44px;
      background: rgba(0, 0, 0, 0);
      border: 1px solid #ff7b00;
      box-shadow: 0px 2px 4px 0px rgba(255, 123, 0, 0.25);
      font-size: 18px;
      color: #ff7b00;
      display: block;
    }
  }
  .banner {
    height: 100%;
    width: 100%;
  }
  .main {
    // width: 1200px;
    // margin: 0 auto;
    .count {
      width: 100%;
      height: 600px;
      background: url(https://cdn.sdh365.com/ow/dbg.png) no-repeat;
      background-size: contain;
      background-position: center;
      margin: 76px 0 60px;
    }
    .cup {
      background: #f7f7f7;
      margin-bottom: 60px;
      .flexbox {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .item_cup {
          width: 380px;
          height: 420px;
          padding: 32px 0 20px;
          box-sizing: border-box;
          background: #fff;
          position: relative;
          h5 {
            font-size: 26px;
            font-weight: 700;
            text-align: center;
            color: #333333;
            margin: 10px 0 12px;
            line-height: 38px;
          }
          ul {
            width: 100%;
            text-align: center;
            padding: 0;
            li {
              text-align: center;
              color: #666;
              font-size: 18px;
              font-weight: 500;
              margin-bottom: 10px;
              list-style: none;
            }
          }
          .img_cup {
            width: 63px;
            height: 63px;
            margin: 0 auto;
            img {
              width: 100%;
            }
            .imgshow {
              display: block;
            }
            .changeshow {
              display: none;
            }
          }
          span {
            width: 160px;
            height: 42px;
            line-height: 42px;
            background: #ffffff;
            border: 1px solid #ff7b00;
            border-radius: 4px;
            display: block;
            font-weight: 500;
            text-align: center;
            color: #ff7b00;
            position: absolute;
            bottom: 20px;
            left: 50%;
            margin-left: -80px;
            cursor: pointer;
          }
        }
        .item_cup:hover {
          background: #ff7b00;
          .imgshow {
            display: none;
          }
          .changeshow {
            display: block;
          }
          h5 {
            color: #fff;
          }
          ul {
            li {
              color: #fff;
            }
          }
        }
      }
      h4 {
        font-size: 36px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        margin-bottom: 16px;
      }
      p {
        font-size: 26px;
        font-weight: 500;
        text-align: center;
        color: #666666;
        margin-bottom: 38px;
      }
    }
    .contain {
      width: 100%;
      margin: 0 auto;
      padding: 80px 0;

      .center_cup {
        width: 1200px;
        margin: 0 auto;

        h5 {
          font-size: 36px;
          font-weight: 500;
          text-align: center;
          color: #ffffff;
          margin-bottom: 57px;
        }
        .count_cup {
          width: 100%;
          padding: 0 40px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .count_item {
            width: 235px;
            margin-bottom: 90px;
            margin-right: 198px;
            p {
              font-size: 22px;
              margin-bottom: 10px;
              color: #fff;
              span {
                display: inline-block;
                font-size: 48px;
                font-weight: bold;
                color: #fff;
              }
            }
          }
          .count_item:nth-child(3n) {
            margin-right: 0px;
          }
        }
      }
    }
    .center {
      width: 1330px;
      margin: 0 auto;
      overflow: hidden;

      .flex_carousel {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .top {
          display: flex;
          justify-content: space-between;
          .left {
            h5 {
              width: 200px;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 22px;
              font-weight: 700;
              color: #333333;
              margin-bottom: 10px;
              overflow: hidden;
            }
            p {
              font-size: 16px;
              font-weight: 500;
              color: #333333;
              width: 250px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .tag {
              display: flex;
              justify-content: flex-start;
              margin-top: 14px;
              flex-wrap: wrap;
              height: 30px;
              overflow: hidden;
              width: 350px;
              span {
                display: block;
                padding: 0px 12px;
                height: 28px;
                line-height: 28px;
                box-sizing: border-box;
                background: rgba(255, 123, 0, 0.1);
                border-radius: 16px;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                color: #ff7b00;
                margin-right: 8px;
                margin-bottom: 5px;
              }
              span:last-child {
                margin-right: 0px;
              }
            }
          }
          .right {
            width: 70px;
            height: 70px;
            border-radius: 70px;
            overflow: hidden;
            position: absolute;
            top: 22px;
            right: 20px;
            img {
              width: 100%;
            }
          }
        }
        .bottom {
          position: absolute;
          width: 340px;
          left: 20px;
          bottom: 17px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .consultNumber {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: LEFT;
            color: #999999;
          }
          .btns {
            width: 123px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            background: linear-gradient(93deg, #ff7b00 20%, #ff9e44 100%);
            border-radius: 19px;
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
            cursor: pointer;
          }
        }
        .item {
          width: 380px;
          height: 220px;
          border-radius: 4px;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
          margin-bottom: 30px;
          margin-right: 28px;
          padding: 22px 20px 17px;
          box-sizing: border-box;
          position: relative;
        }
        .item:nth-child(3n) {
          margin-right: 0px;
        }
        .item2 {
          width: 380px;
          height: 280px;
          border-radius: 4px;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
          background: #fff;
          margin-right: 28px;
          margin-bottom: 54px;
          .card {
            padding: 22px 20px 28px;
            box-sizing: border-box;
            width: 100%;
            height: 120px;
            background: #fffcfa;
            border-radius: 4px 4px 0px 0px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: space-between;
            align-items: center;
            .txt {
              h6 {
                width: 200px;
                margin-top: 0;
                font-size: 20px;
                font-weight: 700;
                color: #333333;
                margin-bottom: 13px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
              p {
                font-size: 16px;
                font-weight: 500;
                color: #333333;
              }
            }
            .img_cup {
              width: 70px;
              height: 70px;
              border-radius: 70px;
              overflow: hidden;
              img {
                width: 100%;
              }
            }
          }
          .infor {
            padding: 20px 25px 24px;
            box-sizing: border-box;
            p {
              font-size: 16px;
              font-weight: 500;
              color: #999999;
              margin-bottom: 10px;
              display: flex;
              justify-content: flex-start;
              span:nth-child(2) {
                width: 200px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-size: 16px;
                font-weight: 500;
                color: #333;
                display: inline-block;
                margin-left: 5px;
              }
            }
            .btn {
              margin-top: 24px;
              display: flex;
              justify-content: space-between;
              span {
                width: 160px;
                height: 38px;
                line-height: 38px;
                text-align: center;
                border-radius: 19px;
                font-size: 16px;
                color: #ff7b00;
                cursor: pointer;
              }
              span:first-child {
                background: linear-gradient(92deg, #ff7b00 20%, #ff9e44 100%);
                color: #fff;
              }
              span:last-child {
                border: 1px solid #ff7b00;
                border-radius: 19px;
              }
            }
          }
        }
        .item2:nth-child(3n) {
          margin-right: 0px;
        }
      }
    }
    padding: 60px 0 57px;
    .part {
      .p {
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        color: #333333;
        margin-bottom: 41px;
      }
      h4 {
        font-size: 36px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        margin-bottom: 58px;
        span {
          color: #ff7b00;
          display: inline-block;
          margin: 0 5px;
        }
      }
      .h4 {
        margin-bottom: 10px;
        margin-top: 75px;
      }
      .flex {
        display: flex;
        justify-content: center;
        .item {
          cursor: pointer;
          position: relative;
          width: 290px;
          height: 364px;
          margin-right: 13px;
          padding: 30px 24px 20px;
          box-sizing: border-box;
          background: #ffffff;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
          .hover {
            width: 290px;
            height: 364px;
            padding: 30px 24px 20px;
            box-sizing: border-box;
            position: relative;
            left: -20px;
            top: -26px;
            -moz-transition: all 0.2s ease-in;
            -webkit-transition: all 0.2s ease-in;
            -o-transition: all 0.2s ease-in;
            -ms-transition: all 0.2s ease-in;
            transition: all 0.2s ease-in;
            span {
              position: absolute;
              left: 50%;
              margin-left: -80px;
              bottom: 20px;
              width: 160px;
              height: 42px;
              line-height: 42px;
              text-align: center;
              background: #ffffff;
              border: 1px solid #ff7b00;
              border-radius: 4px;
              font-weight: 500;
              color: #ff7b00;
            }
            p {
              font-size: 22px;
              font-weight: 500;
              color: #333333;
            }
          }
        }
        .item:last-child {
          margin-right: 0;
        }
        .item:hover {
          .hover {
            position: relative;
            top: -45px;
            left: -35px;
            width: 312px;
            height: 392px;
            padding: 44px 35px 35px;
            box-sizing: border-box;
            box-shadow: none;
            background: #ff7b00;
            p {
              color: #fff;
            }
            span {
              bottom: 30px;
            }
          }
        }
      }
    }
  }
}
</style>