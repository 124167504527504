import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        showChat: false,
        showPopMessage: false
    },
    mutations: {
        showChat(state) {
            state.showChat = true
        },
        hideChat(state){
            state.showChat = false
        },
        showPopMessage(state) {
            state.showPopMessage = true
        },
        hidePopMessage(state){
            state.showPopMessage = false
        }
    },
    getters: {
        getShowChat: state => state.showChat,
        getShowPopMessage: state => state.showPopMessage
    }

})