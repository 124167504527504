<template>
  <div class="news">
    <div class="banner"></div>
    <div class="main">
      <div class="tabs">
        <div
          class="tab-label"
          :class="active == 'qiye' ? 'tab-active' : ''"
          @click="active = 'qiye'"
        >
          企业新闻
        </div>
        <div
          class="tab-label"
          :class="active == 'youxue' ? 'tab-active' : ''"
          @click="active = 'youxue'"
        >
          游学峰会
        </div>
        <div
          class="tab-label"
          :class="active == 'dashi' ? 'tab-active' : ''"
          @click="active = 'dashi'"
        >
          大事记
        </div>
        <div
          class="tab-label"
          :class="active == 'jinqi' ? 'tab-active' : ''"
          @click="active = 'jinqi'"
        >
          近期活动
        </div>
      </div>
      <div v-show="active == 'qiye'">
        <div class="list">
          <div class="item" v-for="c of qiyeList" :key="c.articleId" @click="goarticle(c.articleId)">
            <div
              class="pic"
              :style="{ backgroundImage: 'url(' + c.cover + ')' }"
            ></div>
            <div class="time">
              <span class="day">{{ c.releaseTime.split("-")[2] }}</span>
              <span class="date"
                >{{ c.releaseTime.split("-")[0] }}-{{
                  c.releaseTime.split("-")[1]
                }}</span
              >
            </div>
            <div class="article">
              <h5>{{ c.title }}</h5>
              <span>{{ c.intro }}</span>
            </div>
            <router-link
              class="detail"
              :to="`/news-detail?id=${c.articleId}`"
            ></router-link>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            @size-change="
              (pageSize) => {
                handleSizeChange(pageSize, 'qiye');
              }
            "
            @current-change="
              (currentPage) => {
                handleCurrentChange(currentPage, 'qiye');
              }
            "
            :current-page.sync="qiyeQuery.pageTo"
            :page-size="qiyeQuery.pageSize"
            layout="prev, pager, next, jumper"
            :total="qiyeTotal"
          >
          </el-pagination>
        </div>
      </div>
      <div v-show="active == 'youxue'">
        <div class="list">
          <div class="item" v-for="c of youxueList" :key="c.articleId" @click="goarticle(c.articleId)">
            <div
              class="pic"
              :style="{ backgroundImage: 'url(' + c.cover + ')' }"
            ></div>
            <div class="time">
              <span class="day">{{ c.releaseTime.split("-")[2] }}</span>
              <span class="date"
                >{{ c.releaseTime.split("-")[0] }}-{{
                  c.releaseTime.split("-")[1]
                }}</span
              >
            </div>
            <div class="article">
              <h5>{{ c.title }}</h5>
              <span>{{ c.intro }}</span>
            </div>
            <router-link
              class="detail"
              :to="`/news-detail?id=${c.articleId}`"
            ></router-link>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            @size-change="
              (pageSize) => {
                handleSizeChange(pageSize, 'youxue');
              }
            "
            @current-change="
              (currentPage) => {
                handleCurrentChange(currentPage, 'youxue');
              }
            "
            :current-page.sync="youxueQuery.pageTo"
            :page-size="youxueQuery.pageSize"
            layout="prev, pager, next, jumper"
            :total="youxueTotal"
          >
          </el-pagination>
        </div>
      </div>
      <div v-show="active == 'dashi'">
        <div class="list">
          <div class="item" v-for="c of dashiList" :key="c.articleId" @click="goarticle(c.articleId)">
            <div
              class="pic"
              :style="{ backgroundImage: 'url(' + c.cover + ')' }"
            ></div>
            <div class="time">
              <span class="day">{{ c.releaseTime.split("-")[2] }}</span>
              <span class="date"
                >{{ c.releaseTime.split("-")[0] }}-{{
                  c.releaseTime.split("-")[1]
                }}</span
              >
            </div>
            <div class="article">
              <h5>{{ c.title }}</h5>
              <span>{{ c.intro }}</span>
            </div>
            <router-link
              class="detail"
              :to="`/news-detail?id=${c.articleId}`"
            ></router-link>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            @size-change="
              (pageSize) => {
                handleSizeChange(pageSize, 'dashi');
              }
            "
            @current-change="
              (currentPage) => {
                handleCurrentChange(currentPage, 'dashi');
              }
            "
            :current-page.sync="dashiQuery.pageTo"
            :page-size="dashiQuery.pageSize"
            layout="prev, pager, next, jumper"
            :total="dashiTotal"
          >
          </el-pagination>
        </div>
      </div>
      <div v-show="active == 'jinqi'">
        <div class="list">
          <div class="item" v-for="c of jinqiList" :key="c.articleId" @click="goarticle(c.articleId)">
            <div
              class="pic"
              :style="{ backgroundImage: 'url(' + c.cover + ')' }"
            ></div>
            <div class="time">
              <span class="day">{{ c.releaseTime.split("-")[2] }}</span>
              <span class="date"
                >{{ c.releaseTime.split("-")[0] }}-{{
                  c.releaseTime.split("-")[1]
                }}</span
              >
            </div>
            <div class="article">
              <h5>{{ c.title }}</h5>
              <span>{{ c.intro }}</span>
            </div>
            <router-link
              class="detail"
              :to="`/news-detail?id=${c.articleId}`"
            ></router-link>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            @size-change="
              (pageSize) => {
                handleSizeChange(pageSize, 'jinqi');
              }
            "
            @current-change="
              (currentPage) => {
                handleCurrentChange(currentPage, 'jinqi');
              }
            "
            :current-page.sync="jinqiQuery.pageTo"
            :page-size="jinqiQuery.pageSize"
            layout="prev, pager, next, jumper"
            :total="jinqiTotal"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "News",
  data() {
    return {
      active: "qiye",
      qiyeQuery: {
        classify: 1,
        pageTo: 0,
        pageSize: 5,
      },
      youxueQuery: {
        classify: 2,
        pageTo: 0,
        pageSize: 5,
      },
      dashiQuery: {
        classify: 3,
        pageTo: 0,
        pageSize: 5,
      },
      jinqiQuery: {
        classify: 4,
        pageTo: 0,
        pageSize: 5,
      },
      qiyeTotal: 0,
      youxueTotal: 0,
      dashiTotal: 0,
      jinqiTotal: 0,
      qiyeList: [],
      youxueList: [],
      dashiList: [],
      jinqiList: [],
    };
  },
  created() {
    this.getQiyeList();
    this.getYouxueList();
    this.getDashiList();
    this.getJinqiList();
  },
  methods: {
    getQiyeList() {
      request.post("/in/website/articleList", this.qiyeQuery).then((res) => {
        this.qiyeList = res.data.items;
        this.qiyeTotal = res.data.totalItemCount;
      });
    },
    getYouxueList() {
      request.post("/in/website/articleList", this.youxueQuery).then((res) => {
        this.youxueList = res.data.items;
        this.youxueTotal = res.data.totalItemCount;
      });
    },
    getDashiList() {
      request.post("/in/website/articleList", this.dashiQuery).then((res) => {
        this.dashiList = res.data.items;
        this.dashiTotal = res.data.totalItemCount;
      });
    },
    getJinqiList() {
      request.post("/in/website/articleList", this.jinqiQuery).then((res) => {
        this.jinqiList = res.data.items;
        this.jinqiTotal = res.data.totalItemCount;
      });
    },
    handleSizeChange(pageSize, type) {
      if (type == "qiye") {
        this.qiyeQuery.pageSize = pageSize;
        this.getQiyeList();
      }
      if (type == "youxue") {
        this.youxueQuery.pageSize = pageSize;
        this.getYouxueList();
      }
      if (type == "dashi") {
        this.dashiQuery.pageSize = pageSize;
        this.getDashiList();
      }
      if (type == "jinqi") {
        this.jinqiQuery.pageSize = pageSize;
        this.getJinqiList();
      }
    },
    handleCurrentChange(currentPage, type) {
      if (type == "qiye") {
        this.qiyeQuery.pageTo = currentPage;
        this.getQiyeList();
      }
      if (type == "youxue") {
        this.youxueQuery.pageTo = currentPage;
        this.getYouxueList();
      }
      if (type == "dashi") {
        this.dashiQuery.pageTo = currentPage;
        this.getDashiList();
      }
      if (type == "jinqi") {
        this.jinqiQuery.pageTo = currentPage;
        this.getJinqiList();
      }
    },
    goarticle(articleId) {
      this.$router.push("/news-detail?id=" + articleId);
    },
  },
};
</script>

<style lang="less" scoped>
.news{
  text-align: center;
  background: #f7f7f7;
}
.banner {
  height: 620px;
  background-image: url(../../assets/banner_news.png);
  background-position: center;
  background-size: contain;
}
.main {
  display: inline-block;
  width: 1200px;
  padding-top: 100px;
  padding-bottom: 180px;
  text-align: left;
  .tabs {
    width: 1200px;
  }
  .tab-label {
    display: inline-block;
    line-height: 34px;
    font-size: 24px;
    text-align: center;
    color: #666666;
    width: 300px;
    padding-bottom: 20px;
    border-bottom: 1px solid #999999;
    margin-bottom: 48px;
    font-weight: 700;
    cursor: pointer;
  }
  .tab-active {
    color: #ff7b00;
    border-bottom: 2px solid #ff7b00;
  }
  .list {
    .item {
      width: 1200px;
      height: 200px;
      background: #ffffff;
      margin-bottom: 20px;
      .pic {
        float: left;
        width: 200px;
        height: 200px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #ffffff;
      }
      .time {
        float: left;
        width: 111px;
        text-align: center;
        margin-top: 66px;
        // padding: 0 26px;
        .day {
          display: block;
          height: 34px;
          font-size: 24px;
          font-weight: 700;
          color: #333333;
        }
        .date {
          height: 14px;
          font-size: 10px;
          color: #333333;
        }
      }
      .article {
        float: left;
        width: 888px;
        padding-left: 29px;
        margin-top: 48px;
        border-left: 1px solid #eeeeee;
        h5 {
          line-height: 34px;
          font-size: 24px;
          color: #333333;
          font-weight: 700;
          margin-bottom: 21px;
          width: 800px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        span {
          font-size: 10px;
          color: #666666;
          display: inline-block;
          width: 670px;
          line-height: 25px;
          font-size: 18px;
          display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
          word-break: break-all;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
          -webkit-line-clamp: 3; /** 显示的行数 **/
          overflow: hidden;
        }
      }
      .detail {
        width: 54px;
        height: 54px;
        background-image: url(../../assets/go.png);
        background-position: center;
        background-size: contain;
        float: right;
        margin-top: -73px;
        margin-right: 40px;
      }
    }
    .item:hover {
      cursor: pointer;
      .article {
        h5 {
          color: #ff7b00;
        }
      }
      .detail {
        background-image: url(../../assets/go_h.png);
      }
    }
  }
}
</style>

<style lang="less">
.main {
  .pagination {
    width: 1200px;
    margin-top: 88px;
    .el-icon-arrow-left:before {
      content: "上一页";
      color: #666666;
      font-size: 16px;
      font-weight: 500;
    }
    .el-icon-arrow-right:before {
      content: "下一页";
      color: #666666;
      font-size: 16px;
      font-weight: 500;
    }
    .el-pagination__jump {
      font-size: 16px;
      color: #333333;
    }
    .el-pagination {
      text-align: center;
      margin: 80px auto 0;
      font-weight: 500;
      .btn-prev,
      .btn-next {
        height: 38px;
        line-height: 38px;
        width: 76px;
        border-radius: 10px;
        margin-right: 8px;
      }
    }
    .el-pagination__editor.el-input .el-input__inner {
      height: 38px;
      line-height: 38px;
    }
    .el-pagination__editor.el-input {
      width: 76px;
    }
    .el-pager li.active {
      background: #ff7b00;
      color: #fff;
    }
    .el-pager li {
      width: 38px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      font-size: 16px;
      color: #333333;
      border-radius: 10px;
      margin-right: 10px;
    }
    .el-pager li:hover {
      background: #ff7b00;
      color: #fff;
    }
  }
}
</style>