<template>
  <div class="headquarters">
    <el-carousel :interval="3000" arrow="never">
      <el-carousel-item>
        <img class="banner" src="https://cdn.sdh365.com/ow/ss_20210914134949.jpg" alt="" />
        <div class="carousel-btn">
          <button class="btn1" @click="showChat">立即咨询</button>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <img class="banner" src="https://cdn.sdh365.com/ow/ss_20210914134955.jpg" alt="" />
        <div class="carousel-btn">
          <button class="btn1" @click="showChat">立即咨询</button>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="main" style="padding-bottom: 0">
      <div class="part">
        <h4>区域总部<span>落户服务</span></h4>
        <p class="p">
          专注于为地方政府提供招商引资、产业落地、成果转化等服务，助力当地企业创新转型升级和区域经济发展
        </p>
        <div class="contain">
          <div class="img_cup">
            <img src="../../assets/pic_home2.png" class="left" />
          </div>
          <div class="right">
            <div>· 多联动搭建数字产业链</div>
            <p>
              集数字生态产业园、数字峰会经济、线上资源共享平台、直播网红体于一体
            </p>
            <div>· 大流量品质高峰论坛</div>
            <p>超2亿次传播曝光、对接政商产学研高端资源、人脉、资源精准触达</p>
            <div>· 全方位资本孵化</div>
            <p>
              对接区域交易所、设立资本孵化营、共建产业基金、助力区域企业转型发展
            </p>
            <div>· 高效益企业生态链</div>
            <p>
              纳斯达克上市、科技创新、产业投资、中小企业入驻、多重效益推区域经济发展
            </p>
            <div>· 多渠道贡献企业税收</div>
            <p>
              招商引资、产业入驻、政策扶持、平台引进、多渠道稳定保证提升区域税收
            </p>
            <el-button class="btn1" type="primary" @click="showPopMessage">获取专家小组服务方案</el-button>
            <el-button class="btn2" type="primary" @click="showChat">在线咨询</el-button>
          </div>
        </div>
      </div>
      <div class="part part2">
        <h4>多联动搭建<span>数字产业布局</span></h4>
        <p class="p">
          为各行业提供工具、做好链接、建好生态，多形式助力区域企业数字化转型
        </p>
        <div class="imgbox">
          <img src="../../assets/d01.png" alt="" />
        </div>
        <div class="small_cup margin">
          <h5>打造直播网红综合体</h5>
          <p class="p1">
            打通地方企业进入互联网直播经济体和直播网红资源体的通道，打通数据、流量、服务的入口
          </p>
          <div class="img">
            <img src="../../assets/d02.png" alt="" />
          </div>
          <p class="p2">
            数据、流量、服务入口=链接人和货的物理空间+互联网直播经济体+直播网红资源体
          </p>
        </div>
        <div class="small_cup margin">
          <h5>建设直播带货频道</h5>
          <p class="p1 p01">
            共同推动当地政府、企业、农业联动直播，实现“农政商”网络一体化赋能
          </p>
          <div class="flex_img">
            <div class="img_c">
              <img src="https://cdn.sdh365.com/ow/d03.png" alt="" />
            </div>
            <div class="img_c">
              <img src="https://cdn.sdh365.com/ow/d04.png" alt="" />
            </div>
            <div class="img_c">
              <img src="https://cdn.sdh365.com/ow/d05.png" alt="" />
            </div>
          </div>
          <div class="onlinebtn" @click="showChat">在线咨询</div>
        </div>
        <div class="small_cup margin1">
          <h5>享受科技新媒体效应</h5>
          <p class="p1 p02">
            把握互联网环境下市场的特点和政府需求，打造有内涵底蕴和开放创新的城市IP，提升城市综合竞争力
          </p>
          <div class="flex_img">
            <div class="flex-item">
              <div class="icon">
                <img src="../../assets/d06.png" alt="" />
              </div>
              <div class="rightxt">
                <h5>网红城市</h5>
                <p>
                  结合当地历史、名俗民风、文化，特色公园、等风
                  景名胜，提炼特色，借助新媒体渠道，引爆市场热度。
                </p>
              </div>
            </div>
            <div class="flex-item">
              <div class="icon">
                <img src="../../assets/d07.png" alt="" />
              </div>
              <div class="rightxt">
                <h5>线上招商</h5>
                <p>
                  根据政府的产业发展规划，投资政策、投资环境、承载平台等，在线发布招商需求，并依据大数据支撑，精准对接推送至目标企业。
                </p>
              </div>
            </div>
            <div class="flex-item">
              <div class="icon">
                <img src="../../assets/d08.png" alt="" />
              </div>
              <div class="rightxt">
                <h5>数字货架</h5>
                <p>
                  针对当地优质的企业产品，创新产品，输出新媒体解决方案，打通供销双方交互平台。
                </p>
              </div>
            </div>
            <div class="flex-item">
              <div class="icon">
                <img src="../../assets/d09.png" alt="" />
              </div>
              <div class="rightxt">
                <h5>新产业研习社</h5>
                <p>
                  根据产业规划，邀请重点产业的专家，及在该产业耕耘多年的企业家，共同开展产业游学，产业论坛。
                </p>
              </div>
            </div>
          </div>
          <div class="onlinebtn" @click="showChat">在线咨询</div>
        </div>
      </div>
    </div>

    <div class="big_cup margin1" style="margin-top: 0">
      <h4>举办大流量高峰论坛赋能</h4>
      <p class="p1 p03">
        超2亿次传播曝光，对接政商产学研高端资源、人脉、资源精准触达
      </p>
      <div class="swiper_cup">
        <div class="swiper_flex">
          <div class="swiper_img" v-for="(i, index) in 6" :key="index">
            <img :src="require(`../../assets/move${index}.png`)" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="main" style="padding-top: 0">
      <div class="main" style="padding-bottom: 0">
        <div class="part part2">
          <h4>全方位资本<span>孵化助力</span></h4>
          <p class="p p1">
            通过3种渠道方式精准赋能孵化，实现区域企业全面升级与价值倍增
          </p>
          <div class="small_cup margin2" style="margin-top: 0">
            <h5>享受科技新媒体效应</h5>
            <p class="p1 p04">
              把握互联网环境下市场的特点和政府需求，打造有内涵底蕴和开放创新的城市IP，提升城市综合竞争力
            </p>
            <div class="img_01">
              <img src="../../assets/d10.png" alt="" />
            </div>
          </div>
          <div class="small_cup margin2" style="margin-top: 0">
            <h5>设立资本孵化营</h5>
            <p class="p1 p04">
              个性化制定系统孵化成长方案，实现企业资本0-10000四个阶段的资本价值提升落地
            </p>
            <div class="img_02">
              <img src="../../assets/d11.png" alt="" />
            </div>
          </div>
          <div class="small_cup margin3" style="margin-top: 0">
            <h5>与政府共建产业基金</h5>
            <p class="p1 p04">
              根据区域产业特征打造全球数字生态科创加速器，专注科技创新创业企业的成长期投资
            </p>
            <div class="flexcup">
              <p class="txt"><span>700万</span>企业会员</p>
              <p class="txt"><span>1万名</span>导师专家</p>
              <p class="txt"><span>100+</span>产业资源</p>
              <p class="txt"><span>30家</span>投资机构</p>
            </div>
            <div class="img_03">
              <img src="../../assets/d12.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="main" style="padding-top: 0; padding-bottom: 0">
        <div class="part part2">
          <h4>引进企业生态链<span>引领招商效益</span></h4>
          <p class="p p3">
            以区域招商需求为切入点，引进重点产业，丰富产业链条，实现产业高端化、集群化
          </p>
          <div class="img_c">
            <img src="../../assets/d13.png" alt="" />
          </div>
        </div>
        <div class="part3">
          <h5>与政府共建产业基金</h5>
          <p class="p">
            根据区域产业特征打造全球数字生态科创加速器，专注科技创新创业企业的成长期投资
          </p>
          <div class="img">
            <img src="../../assets/d14.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Headquarters',
  data() {
    return {}
  },
  methods: {
    showChat() {
      this.$store.commit('showChat')
    },
    showPopMessage() {
      this.$store.commit('showPopMessage')
    },
  },
  created() {},
  mounted() {
    var oUl = document.querySelectorAll('.swiper_flex')[0]
    //console.log(oUl.innerHTML + oUl.innerHTML);
    // 获取ul里的li进行拼接，然后重新赋给ul
    oUl.innerHTML = oUl.innerHTML + oUl.innerHTML // oUl.innerHTML += oUl.innerHTML
    // 由于ul初始时是4张图片的宽度，现在把宽度变成是8张的宽度
    // 获取li，根据li的宽度重新设置ul的宽度
    var oLies = document.querySelectorAll('.swiper_img') // 获取li
    oUl.style.width = oLies[0].offsetWidth * oLies.length + 'px' // 设置宽度，li的宽度*li的个数

    var speed = -3

    setInterval(function () {
      if (oUl.offsetLeft <= -oUl.offsetWidth * 0.5) {
        oUl.style.left = '0px'
      }

      oUl.style.left = oUl.offsetLeft + speed + 'px'
    }, 1000 / 30)
  },
}
</script>
<style lang="less" scoped>
.headquarters {
  overflow-x: hidden;
  .el-carousel {
    height: 620px;
  }
  .el-carousel__container {
    position: relative;
    .el-carousel__item {
      height: 620px;
      .carousel-btn {
        position: absolute;
        top: 455px;
        left: 42%;
        .btn1 {
          width: 330px;
          height: 55px;
          border: 1px solid #ffffff;
          border-radius: 31px;
          cursor: pointer;
          font-weight: 500;
          background-color: #ffffff;
          color: #131b40;
          font-size: 18px;
          box-shadow: 0px 10px 6px 0px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
  .banner {
    height: 100%;
    width: 100%;
  }
  .big_cup {
    width: 100%;
    h4 {
      font-size: 36px;
      font-weight: 500;
      text-align: center;
      color: #333333;
    }
    .p03 {
      margin: 10px 0 48px;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      color: #666666;
    }
    .swiper_cup {
      width: 100%;
      height: 260px;
      position: relative;
      .swiper_flex {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: flex-start;
        .swiper_img {
          width: 318px;
          height: 260px;
          border: 1px solid #fff;
          box-sizing: border-box;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  @-webkit-keyframes bounce-up {
    25% {
      -webkit-transform: translateY(10px);
    }
    50%,
    100% {
      -webkit-transform: translateY(0);
    }
    75% {
      -webkit-transform: translateY(-10px);
    }
  }
  .main {
    width: 1200px;
    margin: 0 auto;
    padding: 100px 0 311px;
    box-sizing: border-box;
    .part3 {
      margin-top: 106px;
      h5 {
        font-size: 36px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        margin-bottom: 17px;
      }
      .p {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: #666666;
        margin-bottom: 44px;
      }
      .img {
        width: 998px;
        height: 666px;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
    }
    .part {
      h4 {
        font-size: 36px;
        font-weight: 700;
        color: #333333;
        margin-bottom: 10px;
        text-align: center;
        span {
          font-weight: 700;
          color: #ff7b00;
        }
      }
      .p {
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #666;
      }
      .contain {
        width: 1165px;
        margin: 0 auto;
        margin-top: 69px;
        display: flex;
        justify-content: flex-start;
        .img_cup {
          height: 540px;
          width: 409px;
          margin-right: 68px;
          margin-top: 45px;
          flex: 1;
          img {
            width: 100%;
            animation: bounce-up 3s linear infinite;
          }
        }
        .right {
          display: inline-block;
          div {
            height: 34px;
            font-size: 24px;
            color: #333333;
          }
          div:hover {
            color: #ff7b00;
          }
          p {
            font-size: 16px;
            color: #666666;
            margin-bottom: 25px;
            margin-left: 19px;
            margin-top: 4px;
          }
          .btn1 {
            min-width: 220px;
            height: 46px;
            background: #ff7b00;
            border-radius: 4px;
            border-color: unset;
            font-size: 18px;
            color: #ffffff;
            margin-left: 20px;
          }
          .btn1:hover {
            background-color: #e76f00;
          }
          .btn2 {
            width: 218px;
            height: 44px;
            background: rgba(0, 0, 0, 0);
            border: 1px solid #ff7b00;
            box-shadow: 0px 2px 4px 0px rgba(255, 123, 0, 0.25);
            font-size: 18px;
            color: #ff7b00;
            margin-left: 20px;
          }
          .btn2:hover {
            background: rgba(255, 123, 0, 0.1);
          }
          .btn1,
          .btn2 {
            margin-top: 16px;
          }
        }
      }
    }
    .part2 {
      .img_c {
        width: 956px;
        height: 391px;
        margin: auto;
        img {
          width: 100%;
        }
      }
      h4 {
        margin-bottom: 24px;
      }
      .p {
        margin-bottom: 38px;
        font-size: 26px;
        color: #333;
      }
      .p1 {
        margin-bottom: 56px;
      }
      .p2 {
        font-size: 18px;
        margin-bottom: 43px;
      }
      .p3 {
        font-size: 20px;
        margin-bottom: 74px;
      }
      .imgbox {
        width: 1126px;
        height: 421px;
        img {
          width: 100%;
        }
      }

      .small_cup {
        margin-top: 100px;
        .flexcup {
          display: flex;
          justify-content: center;
          margin-bottom: 60px;
          .txt {
            font-size: 36px;
            font-weight: 700;
            margin-right: 30px;
            color: #333333;
            font-size: 20px;
            width: 227px;
            height: 67px;
            line-height: 67px;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            text-align: center;
            span {
              display: inline-block;
              font-weight: 700;
              font-size: 36px;
              color: #333333;
            }
          }
          .txt:last-child {
            margin-right: 0;
          }
        }
        .img {
          width: 967px;
          height: 372px;
          margin: 0 auto 39px;
          img {
            width: 100%;
          }
        }
        h5 {
          font-size: 30px;
          font-weight: 500;
          text-align: center;
          color: #333333;
          margin-bottom: 15px;
        }
        .p1 {
          font-size: 20px;
          font-weight: 500;
          text-align: center;
          color: #666666;
          margin-bottom: 80px;
        }
        .p01 {
          margin-bottom: 66px;
          text-align: center;
        }
        .p02 {
          margin-bottom: 38px;
          text-align: center;
        }
        .p04 {
          margin-bottom: 43px;
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          color: #666666;
        }
        .img_01 {
          width: 1093px;
          height: 442px;
          margin: auto;
          img {
            width: 100%;
          }
        }
        .img_02 {
          width: 1076px;
          height: 374px;
          margin: auto;
          img {
            width: 100%;
          }
        }
        .img_03 {
          width: 1165px;
          height: 159px;
          margin: auto;
          img {
            width: 100%;
          }
        }
        .p2 {
          font-size: 26px;
          font-weight: 500;
          text-align: center;
          color: #666666;
        }
        .flex_img {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 40px;
          .flex-item {
            width: 590px;
            height: 165px;
            padding: 0 50px 0 31px;
            margin-bottom: 20px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-left: 18px solid #ff7b00;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            .icon {
              width: 58px;
              height: 58px;
              margin-right: 31px;
              flex-shrink: 0;
              img {
                width: 100%;
              }
            }
            .rightxt {
              h5 {
                margin-bottom: 7px;
                font-size: 24px;
                font-weight: 800;
                color: #333333;
                text-align: left;
              }
              p {
                font-size: 18px;
                font-weight: 500;
                color: #333333;
              }
            }
          }
          .flex-item:nth-child(3),
          .flex-item:nth-child(4) {
            margin-bottom: 0;
          }
          .img_c {
            width: 390px;
            height: 370px;
            img {
              width: 100%;
            }
          }
        }
        .onlinebtn:hover {
          background: rgba(255, 123, 0, 0.1);
        }
        .onlinebtn {
          width: 220px;
          height: 46px;
          margin: 0 auto;
          line-height: 46px;
          text-align: center;
          border: 1px solid #ff7b00;
          border-radius: 4px;
          box-shadow: 0px 2px 4px 0px rgba(255, 123, 0, 0.25);
          font-size: 18px;
          font-weight: 700;
          color: #ff7b00;
          cursor: pointer;
        }
      }
    }
    .margin {
      margin-bottom: 100px;
    }
    .margin1 {
      margin-bottom: 69px;
    }
    .margin2 {
      margin-bottom: 90px;
    }
    .margin3 {
      margin-bottom: 139px;
    }
  }
}
</style>